import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    marginLeft: 0,
  },
  label: {
    marginLeft: 7,
    fontSize: '0.75rem',
    lineHeight: 'calc(16/12)',
  },
});
