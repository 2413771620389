const STORAGE = window.localStorage;
export const TOKEN_KEY = 'token';
export const USERNAME_KEY = 'username';

export function saveToStorage(key, value) {
  STORAGE.setItem(key, value);
}

export function getFromStorage(key) {
  return STORAGE.getItem(key);
}

export function removeFromStorage(key) {
  STORAGE.removeItem(key);
}
