import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

const FormContainer = withStyles({
  root: {
    padding: '20px 40px',
    display: 'flex',
    flexFlow: 'column nowrap',
    minHeight: '100%',
  },
})(Box);

export default FormContainer;
