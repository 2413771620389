import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  IconButton,
} from '@material-ui/core';

import { CloseIcon, WarningIcon } from '../Icons';
import { ButtonLoading } from '../ButtonLoading';
import { useStyles } from './confirmDialogStyles';

export const ConfirmDialog = ({
  wrapperStyles,
  open,
  loading,
  disableConfirm,
  onClose,
  onConfirm,
  title,
  description,
  confirmBtnCaption,
  confirmBtnColor,
  closeBtnCaption,
  showCloseIcon,
  withActions,
  withAlert,
  withConfirm,
  children,
}) => {
  const {
    dialogPaper,
    titleRoot,
    descriptionRoot,
    descriptionTextRoot,
    actionsRoot,
    closeIcon,
    titleRootChild,
  } = useStyles(wrapperStyles);

  const closeHandler = (event, reason) => {
    if (!loading) {
      onClose?.(event, reason);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      classes={{
        paper: dialogPaper,
      }}
      PaperProps={{ elevation: 0 }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {showCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={closeHandler}
          classes={{
            root: closeIcon,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogTitle
        id="alert-dialog-title"
        classes={{
          root: titleRoot,
        }}
        disableTypography
      >
        {withAlert && (
          <div style={{ marginRight: 4 }}>
            <WarningIcon />
          </div>
        )}
        <Typography classes={{ root: titleRootChild }} variant="h6">
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent classes={{ root: descriptionRoot }}>
        <DialogContentText
          id="alert-dialog-description"
          classes={{ root: descriptionTextRoot }}
        >
          {description}
        </DialogContentText>

        <div style={{ paddingBottom: withActions ? 0 : 24 }}>{children}</div>
      </DialogContent>
      {withActions && (
        <DialogActions classes={{ root: actionsRoot }}>
          <Button onClick={onClose} variant="outlined" disabled={loading}>
            {closeBtnCaption}
          </Button>
          {withConfirm && (
            <ButtonLoading
              onClick={onConfirm}
              isLoading={loading}
              variant="contained"
              color={confirmBtnColor}
              disabled={disableConfirm}
              autoFocus
            >
              {confirmBtnCaption}
            </ButtonLoading>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmBtnCaption: PropTypes.string,
  confirmBtnColor: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
  ]),
  closeBtnCaption: PropTypes.string,
  withActions: PropTypes.bool,
  withConfirm: PropTypes.bool,
  withAlert: PropTypes.bool,
  disableConfirm: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  wrapperStyles: PropTypes.object,
};

ConfirmDialog.defaultProps = {
  loading: false,
  withActions: true,
  withConfirm: true,
  withAlert: false,
  disableConfirm: false,
  closeBtnCaption: 'Отмена',
  confirmBtnCaption: 'Ok',
  confirmBtnColor: 'secondary',
  showCloseIcon: false,
};
