import React from 'react';
import PropTypes from 'prop-types';
import { SomethingWentWrongScreen } from './screens/SomethingWentWrongScreen';
import { LoadingScreen } from './screens/LoadingScreen';
import { NotFoundScreen } from './screens/NotFoundScreen';

export const SCREENS = {
  sww: 'somethingWentWrongScreen',
  loading: 'loadingScreen',
  notFound: 'notFoundScreen',
};

const ScreenNameToComponentMap = {
  [SCREENS.sww]: <SomethingWentWrongScreen />,
  [SCREENS.loading]: <LoadingScreen />,
  [SCREENS.notFound]: <NotFoundScreen />,
};

const Screens = ({ screen, screenProps, children }) => {
  return ScreenNameToComponentMap[screen]
    ? React.cloneElement(ScreenNameToComponentMap[screen], screenProps)
    : children;
};

Screens.propTypes = {
  screen: PropTypes.oneOf([SCREENS.sww, SCREENS.loading, SCREENS.notFound]),
  screenProps: PropTypes.object,
  children: PropTypes.node,
};

export default Screens;
