import React from 'react';

export const DoneIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.05 8.55145L10.1014 16.5L6 12.3986L7.05145 11.3471L10.1014 14.3896L16.9985 7.5L18.05 8.55145Z"
      fill="currentColor"
    />
  </svg>
);
