import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  header: {
    textAlign: 'center',
    lineHeight: '16px',
    width: '100%',
  },
  topLine: {
    width: '100%',
    height: '2px',
  },
  logoWrapper: {
    margin: '22px 0 6px',
    height: '16px',
    '&__noGradientLine': {
      marginTop: '13px',
    },
  },
}));
