import React from 'react';

export const CheckboxActiveIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6ZM16.5575 10.0017C16.8346 9.69384 16.8096 9.21962 16.5017 8.94253C16.1938 8.66544 15.7196 8.69039 15.4425 8.99828L11.4714 13.4107L9.03033 10.9697C8.73744 10.6768 8.26256 10.6768 7.96967 10.9697C7.67678 11.2626 7.67678 11.7374 7.96967 12.0303L10.9697 15.0303C11.1152 15.1758 11.314 15.2551 11.5197 15.2497C11.7254 15.2443 11.9198 15.1547 12.0575 15.0017L16.5575 10.0017Z"
      fill="currentColor"
    />
  </svg>
);
