import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '2rem',
  },
  selected: {
    background: 'initial !important',
    cursor: 'auto',
    '&:hover': {
      background: '#0077cc14 !important',
    },
  },
  circle: {
    width: 6,
    height: 6,
    display: 'inline-block',
    borderRadius: 3,
    borderColor: theme.palette.grey['500'],
    marginLeft: 'auto',
    marginRight: 12,
    boxSizing: 'border-box',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    color: ({ contrastingLabel }) =>
      contrastingLabel ? theme.palette.text.primary : undefined,
    '& svg': {
      color: theme.palette.grey[500],
      width: '16px',
      height: '16px',
      margin: '0 -3px -3px 1px',
    },
  },
}));
