import { useState, useCallback } from 'react';
import MaterialPagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { InputBase } from '../Inputs';

const MAX_INPUT_LENGTH = 5;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  itemRoot: {
    margin: 0,
    height: '36px',
    width: (enableInput) => (enableInput ? 'auto' : '36px'),
    minWidth: (enableInput) => (enableInput ? '36px' : '32px'),
    padding: (enableInput) => (enableInput ? '0 12px' : undefined),
    borderRadius: '18px',
    fontSize: '14px',
    lineHeight: '36px',
    '&:hover, &:focus': {
      color: (enableInput) =>
        enableInput ? theme.palette.grey[500] : undefined,
      backgroundColor: (enableInput) =>
        enableInput ? 'transparent !important' : undefined,
    },
  },
  itemSelected: {
    backgroundColor: (enableInput) =>
      `${enableInput ? 'transparent' : theme.palette.grey[100]} !important`,
    color: theme.palette.grey[500],
    cursor: 'auto',
  },
  itemEllipsis: {
    '&:hover': {
      color: 'initial !important',
      backgroundColor: 'initial !important',
    },
  },
  inputRoot: {
    '& input': {
      textAlign: 'center',
      padding: '9px 10px',
    },
    '& div': {
      height: '36px',
      width: '60px',
    },
  },
}));

const Pagination = ({ className, enableInput, inputAutoFocus, ...props }) => {
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles(enableInput);

  const handleInputKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' && event.target.value) {
        let newPage = Number(event.target.value);
        const { onChange, count: pageCount } = props;

        /* eslint-disable no-param-reassign */
        if (newPage > pageCount) {
          event.target.value = String(pageCount);
          newPage = pageCount;
        }
        if (newPage < 1) {
          event.target.value = '1';
          newPage = 1;
        }
        /* eslint-enable no-param-reassign */

        onChange(undefined, newPage);
        setInputValue('');
      }
    },
    [setInputValue, props.count, props.onChange]
  );

  const handleInputChange = useCallback(
    (event) => {
      setInputValue(
        event.target.value.replace(/\D/g, '').slice(0, MAX_INPUT_LENGTH)
      );
    },
    [setInputValue]
  );

  return (
    <MaterialPagination
      className={className}
      classes={{ root: classes.root }}
      hideNextButton
      hidePrevButton
      siblingCount={enableInput ? 2 : 1}
      {...props}
      renderItem={(item) =>
        item.selected && enableInput ? (
          <InputBase
            key={item.page}
            className={classes.inputRoot}
            placeholder={String(item.page)}
            onClick={item.onClick}
            onKeyDown={handleInputKeyDown}
            maxLength={MAX_INPUT_LENGTH}
            inputProps={{
              autoFocus: inputAutoFocus,
              onChange: handleInputChange,
              value: inputValue,
            }}
          />
        ) : (
          <PaginationItem
            {...item}
            classes={{
              root: classes.itemRoot,
              selected: classes.itemSelected,
              ellipsis: classes.itemEllipsis,
            }}
          />
        )
      }
    />
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  enableInput: PropTypes.bool,
  count: PropTypes.number,
  onChange: PropTypes.func,
  inputAutoFocus: PropTypes.bool,
};

Pagination.defaultProps = {
  className: '',
  enableInput: false,
};

export default Pagination;
