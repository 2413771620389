import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  ListItemIcon,
  Typography,
  Checkbox,
} from '@material-ui/core';

import { CheckIcon } from '../Icons';
import { useStyles } from './selectStyles';

/**
 *   Компонент элемента выпадающего списка<br>
 *   Реализован на базе
 *   <a href="https://material-ui.com/api/menu-item/#menuitem-api">MenuItem из material</a>, можно использовать все те же пропсы что и у MenuItem<br>
 */
export const SelectMenuItem = forwardRef(
  (
    {
      icon,
      withCheckbox,
      simpleText,
      children,
      selected,
      colored,
      color,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    const circleStyles = {
      backgroundColor: color,
      borderStyle: color ? 'none' : 'solid',
      borderWidth: 1,
    };

    return (
      <MenuItem
        classes={{ root: classes.root, selected: classes.selected }}
        selected={selected}
        ref={ref}
        {...rest}
      >
        {!withCheckbox && !simpleText && (
          <ListItemIcon>{selected && icon}</ListItemIcon>
        )}
        {withCheckbox && !simpleText && (
          <Checkbox
            checked={selected}
            color="primary"
            disableRipple
            size="small"
          />
        )}
        <Typography component="div" noWrap>
          {children}
        </Typography>
        {colored ? (
          <>
            &nbsp; &nbsp; &nbsp;
            <span className={classes.circle} style={circleStyles}></span>
          </>
        ) : null}
      </MenuItem>
    );
  }
);

SelectMenuItem.propTypes = {
  /**
   *   Иконка
   */
  icon: PropTypes.node,
  /**
   *   Состояние выбора
   */
  selected: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /**
   *   Флаг отвечающий за отображение чекбокса
   */
  withCheckbox: PropTypes.bool,
  /**
   *   Флаг отвечающий за отображение элемента без иконок слева
   */
  simpleText: PropTypes.bool,
  /**
   *   Активация цветных кружков
   */
  colored: PropTypes.bool,
  /**
   *   Цвет кружочка справа. Отображается если у компонента Select активен prop - colored
   */
  color: PropTypes.string,
};

SelectMenuItem.defaultProps = {
  icon: <CheckIcon />,
  withCheckbox: false,
  simpleText: false,
};
