import React from 'react';

export const ArrowUpIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.5 14.5L17.5 14.5L12.5 9.5L7.5 14.5Z" fill="currentColor" />
  </svg>
);
