import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  contrastingLabel?: boolean;
}

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      width: '100%',
      borderRadius: 4,
      position: 'relative',
      padding: '0 12px 8px',
      border: '1px solid var(--grey-300)',
    },
    list: {
      overflowY: 'auto',
      margin: '0 -12px 0',
      paddingBottom: 0,
      paddingTop: 0,
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'var(--grey-400)',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: 'var(--grey-500)',
      },
    },
    listItem: {
      position: 'relative',
      padding: '4px 8px',
      '&:hover $option': {
        color: theme.palette.primary.light,
      },
      '&.Mui-selected': {
        backgroundColor: 'unset',
      },
      '&::after': {
        position: 'absolute',
        borderRadius: '3px',
        width: '6px',
        height: '6px',
        right: '12px',
        top: '13px',
      },
    },
    listLabel: {
      height: '16px',
      position: 'relative',
      maxWidth: 'fit-content',
      transform: 'translate(0, -8px)',
      background: 'var(--white)',
      color: ({ contrastingLabel }: StyleProps) => contrastingLabel
        ? theme.palette.text.primary
        : theme.palette.grey[500],
      fontSize: ({ contrastingLabel}: StyleProps) => contrastingLabel
        ? '13px'
        : '12px',
      alignItems: 'center',
      padding: '4px',
      display: 'flex',
    },
    noWrap: {
      fontSize: 'inherit',
      display: '-webkit-box',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    helperText: {
      whiteSpace: 'pre-line',
    },
    option: {
      padding: 0,
      marginRight: 6,
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
  }),
  { index: 1 }
);

export { useStyles };
