export const LIST_TYPES = ['numbered-list', 'bulleted-list'] as const;
export const TEXT_ALIGNS = ['left', 'justify'] as const;
export const RED_COLOR = 'red' as const;
export const GREY_COLOR = 'grey' as const;
export const BLACK_COLOR = 'black' as const;
export const COLOR_NAMES = [RED_COLOR, GREY_COLOR, BLACK_COLOR];
export const COLORS = {
  [BLACK_COLOR]: 'rgb(26,26,26)',
  [GREY_COLOR]: 'rgb(166, 166, 166)',
  [RED_COLOR]: 'rgb(234, 22, 83)',
};
export const LARGE_SIZE = 'large' as const;
export const LARGE_SIZE_VALUE = 18;
export const FONT_SIZES = [LARGE_SIZE] as const;
export const TOOLTIPS = {
  bold: 'Жирный',
  italic: 'Курсив',
  underline: 'Подчеркнутый',
  strikethrough: 'Зачеркнутый',
  bulletedList: 'Маркированный список',
  numberedList: 'Нумерованный список',
  leftAlign: 'По левому краю',
  justify: 'По ширине',
  largeFont: 'Увеличить текст',
  normalFont: 'Обычный шрифт',
  specialCharacters: 'Спецсимволы',
  link: 'Прикрепить ссылку',
};
export const LINK = 'link';
export const INLINE_TYPES = new Set([LINK]);
export const LINK_MODAL = {
  addTitle: 'Добавление ссылки',
  editTitle: 'Изменение ссылки',
  confirmCaption: 'Сохранить',
  labels: {
    text: 'Текст',
    url: 'Ссылка',
  },
};
export const WARNING_BANNERS = {
  title1: 'Уважаемые покупатели!',
  title2: 'Обратите внимание!',
};
export const EMPTY_VALUE = '<p></p>';
