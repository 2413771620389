export const WarningIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
      fill="#E62E6B"
    />
    <path d="M11.0996 8V12H12.8996V8H11.0996Z" fill="white" />
    <path d="M11.0996 14V16H12.8996V14H11.0996Z" fill="white" />
  </svg>
);
