import { useLayoutEffect, RefObject } from 'react';

import { useLatest } from './useLatest';
import {
  getResizeObserver,
  ResizeObserverCallback,
} from '../../utils/resizeObserverStore';

const useResizeObserver = <T extends HTMLElement | null>(
  target: RefObject<T> | T | null,
  callback: ResizeObserverCallback
): ResizeObserver => {
  const resizeObserver = getResizeObserver();
  const storedCallback = useLatest(callback);

  useLayoutEffect(() => {
    let isUnsubscribed = false;
    const targetEl = target && 'current' in target ? target.current : target;

    if (targetEl) {
      const resizeCallback = (
        entry: ResizeObserverEntry,
        observer: ResizeObserver
      ) => {
        if (isUnsubscribed) return;
        storedCallback.current(entry, observer);
      };

      resizeObserver.subscribe(targetEl as HTMLElement, resizeCallback);

      return () => {
        isUnsubscribed = true;
        resizeObserver.unsubscribe(targetEl as HTMLElement, resizeCallback);
      };
    }

    return undefined;
  }, [target, resizeObserver, storedCallback]);

  return resizeObserver.observer;
};

export { useResizeObserver };
