import { Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '../tableStyles';

const TableCellBold = ({ value }) => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.tableCellBoldText}
      variant="inherit"
      color="textPrimary"
      noWrap
    >
      {value}
    </Typography>
  );
};

TableCellBold.propTypes = {
  value: PropTypes.any,
};

TableCellBold.defaultProps = {
  value: '',
};

export default TableCellBold;
