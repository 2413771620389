import { useState } from 'react';
import clsx from 'clsx';
import { IconButton, Typography } from '@material-ui/core';

import { BookmarkIcon, LogoIcon, logoIconColors, SlimArrowRightIcon } from '@21vek/admin-front-components';

import { AdminLink } from '../../routing/AdminLink';
import { UserInfoBlock } from './UserInfoBlock';
import { useStyles } from './mainScreenStyles';

export const MainScreen = ({ disabledSidebar, adminPartsLinks, children }) => {
  const classes = useStyles();
  const [hiddenSidebar, setHiddenSidebar] = useState(
    JSON.parse(localStorage.getItem('hideSidebarMenu')),
  );

  return (
    <div className={classes.base}>
      <div className={clsx(classes.sidebar, {
        [classes.disabled]: disabledSidebar,
        [classes.hidden]: hiddenSidebar,
      })}>
        <div className={classes.sticky}>
          <header className={classes.navHeader}>
            <LogoIcon color={logoIconColors.currentColor} />
          </header>

          <UserInfoBlock />

          <nav>
            {adminPartsLinks.map((adminPart) => (
              <ul key={adminPart.title} className={classes.navList}>
                <li className={clsx(classes.navItem, classes.navAdminPartTitle)}>
                  <span className={classes.navAdminPartIcon}>
                    {adminPart.icon}
                  </span>
                  <Typography variant="body1" noWrap>
                    <b>{adminPart.title}</b>
                  </Typography>
                </li>

                {adminPart.routes.map((route) => (
                  <li key={route.title} className={classes.navItem}>
                    <AdminLink
                      className={classes.navLink}
                      activeClassName={classes.navLinkSelected}
                      to={route.path}
                    >
                      <Typography variant="body1" noWrap component="span">
                        {route.title}
                      </Typography>
                    </AdminLink>
                  </li>
                ))}
              </ul>
            ))}
          </nav>
         
          <div className={clsx(classes.hideSidebarButton, {
            [classes.collapsed]: hiddenSidebar,
            [classes.hidden]: disabledSidebar,
          })}>
            <span className={classes.hideIconWrapper}>
              <BookmarkIcon />
            </span>
            <IconButton onClick={() => {
              setHiddenSidebar(!hiddenSidebar);
              localStorage.setItem('hideSidebarMenu', !hiddenSidebar);
            }}>
              <SlimArrowRightIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <main className={classes.main}>{children}</main>
    </div>
  );
};
