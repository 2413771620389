import React from 'react';
import MaterialTableHead from '@material-ui/core/TableHead';
import PropTypes from 'prop-types';

import { useStyles } from './tableStyles';

const TableHead = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <MaterialTableHead
      className={className}
      classes={{ root: classes.tableHead }}
      component="div"
      {...props}
    />
  );
};

TableHead.propTypes = {
  className: PropTypes.string,
};

TableHead.defaultProps = {
  className: undefined,
};

export default TableHead;
