import React from 'react';

export const LogoutIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 6.4C18 5.6 17.4 5 16.6 5H9.39999C8.59999 5 7.99999 5.6 7.99999 6.4V8H9.39999V6.4H16.6V17H9.39999V16H7.79999V17C7.79999 17.6 8.4 18.5 9.39999 18.5H16.6C17.4 18.5 18 17.6 18 17V6.4ZM14.495 11.505L11.995 9.005L11.005 9.99495L12.3101 11.3H6V12.7H12.3101L11.005 14.005L11.995 14.995L14.495 12.495C14.7683 12.2216 14.7683 11.7784 14.495 11.505Z"
      fill="currentColor"
    />
  </svg>
);
