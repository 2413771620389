import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '8px',
  },
  rootTypographyBody: {
    maxWidth: '532px',
    margin: '0 auto',

    '& > a': {
      color: '#1A1A1A',
      '&:hover': {
        opacity: 0.6,
      },
    },
  },
}));
