import React, { useCallback } from 'react';
import { Typography, Box, Button } from '@material-ui/core';

import { Header } from '../../components';
import { goToMainPage, goToPage } from '../../lib';
import { useAppContext } from '../../hooks';
import { getAvailableMenuByRoles } from '../../routing/routes';
import notFoundImg from '../../assets/NotFound.svg';

import { useStyles } from './notFoundScreenStyles';

const NotFoundScreen = () => {
  const classes = useStyles();
  const { userInfo } = useAppContext();
  const availableRoutes = getAvailableMenuByRoles(userInfo?.roles);
  const handleGoToPage = useCallback(() => availableRoutes.length > 0 ?
    goToPage(availableRoutes[0].routes[0].path) : goToMainPage(), [availableRoutes]);

  return (
    <div className="page pageError">
      <Header withGradientLine={false} />

      <main className="pageError__content">
        <section className="imageSection">
          <img src={notFoundImg} alt="Нет доступа" />
        </section>

        <Typography className={classes.heading} variant="h5">
          Страница не найдена
        </Typography>

        <Typography className={classes.rootTypographyBody} variant="body1">
          Страница не существует. Проверьте ссылку и обновите страницу, если это
          не помогло, вы можете вернуться на главную или&nbsp;
          <a href="mailto:dev@21vek.by">написать в поддержку</a>.
        </Typography>

        <Box mt="32px" mb="20px">
          <Button onClick={handleGoToPage} variant="contained" color="primary">
            На главную
          </Button>
        </Box>
      </main>
    </div>
  );
};

export default NotFoundScreen;
