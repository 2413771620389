import { useCallback, useEffect, useState } from 'react';

import { useWindowSize } from './useWindowSize';

const useLabelWidth = (inputRef) => {
  const [width, setWidth] = useState(undefined);
  const [inputSize, setInputSize] = useState(0);

  const handleResize = useCallback(() => {
    if (inputRef?.current) {
      setInputSize(inputRef.current.clientWidth);
    }
  }, [inputRef]);

  useWindowSize(handleResize);

  useEffect(() => {
    if (inputSize) {
      setWidth(inputSize ? inputSize - 24 : undefined);
    }
  }, [inputSize]);

  return width;
};

export { useLabelWidth };
