import { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
}));

function Form({
  className,
  children,
  disableTitleMargin,
  onSubmit,
  title,
  titleClassName,
}) {
  const classes = useStyles();

  const submitHandler = useCallback(
    (event) => {
      if (document.activeElement?.tagName.toUpperCase() === 'INPUT') {
        document.activeElement.blur();
      }

      onSubmit(event);
    },
    [onSubmit]
  );

  return (
    <form className={className} noValidate onSubmit={submitHandler}>
      {title && (
        <Typography
          className={clsx(
            { [classes.title]: !disableTitleMargin },
            titleClassName
          )}
          variant="h4"
        >
          {title}
        </Typography>
      )}
      {children}
    </form>
  );
}

Form.propTypes = {
  className: PropTypes.string,
  disableTitleMargin: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
};

Form.defaultProps = {
  disableTitleMargin: false,
};

export default Form;
