import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    menuWrapper: {
      padding: '0 0.5rem',
    },
    list: {
      margin: '8px -0.5rem 0',
      paddingBottom: 0,
      paddingTop: 0,
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'var(--grey-400)',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: 'var(--grey-500)',
      },
    },
    saveButton: {
      marginBottom: '0.5rem',
    },
    helperText: {
      marginTop: '1rem',
    },
    menuItem: {
      position: 'relative',
      paddingTop: 4,
      paddingBottom: 4,
      '&:hover $checkbox': {
        color: theme.palette.primary.light,
      },
      '&:last-child': {
        marginBottom: 8,
      },
      '&::after': {
        content: (props) => (props.circleColor ? '""' : 'none'),
        background: (props) => props.circleColor,
        position: 'absolute',
        borderRadius: '3px',
        width: '6px',
        height: '6px',
        right: '12px',
        top: '13px',
      },
    },
    noWrap: {
      fontSize: (props) => props.fontSize || 'inherit',
      display: (props) => props.display || '-webkit-box',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    checkbox: {
      padding: 0,
      marginRight: 6,
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    link: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '&[disabled]': {
        cursor: 'default',
        color: theme.palette.grey[500],
        pointerEvents: 'none',
      },
    },
    count: {
      marginLeft: 'auto',
      width: '60px',
      textAlign: 'right',
    },
  }),
  { index: 1 }
);
