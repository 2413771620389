import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  IconButton,
  TableCell as MaterialTableCell,
  Tooltip,
} from '@material-ui/core';

import { useStyles } from './tableStyles';

export const ActionsCell = ({ actions, row }) => {
  const classes = useStyles();

  return (
    <MaterialTableCell
      className={classes.actionsCell}
      classes={{ root: classes.tableCell, body: classes.tableCellBody }}
      component="div"
    >
      {actions.map(
        ({
          tooltipText,
          icon,
          onClick,
          color = 'primary',
          isSubmittedState,
        }) => {
          const handleClick = (event) => {
            event.stopPropagation();
            onClick(row);
          };

          return (
            <Tooltip key={tooltipText} title={tooltipText}>
              <IconButton
                className={clsx(classes.actionIconButton, {
                  [classes.actionIconButtonSubmitted]:
                    isSubmittedState && isSubmittedState(row.id),
                })}
                onClick={handleClick}
                size="small"
                color={color}
              >
                {icon}
              </IconButton>
            </Tooltip>
          );
        }
      )}
    </MaterialTableCell>
  );
};

export const ActionPropTypes = PropTypes.shape({
  tooltipText: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  isSubmittedState: PropTypes.func,
});

ActionsCell.propTypes = {
  actions: PropTypes.arrayOf(ActionPropTypes).isRequired,
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
