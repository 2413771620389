import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import startOfDay from 'date-fns/startOfDay';

const useStyles = makeStyles((theme) => ({
  iconButtonRoot: {
    color: theme.palette.primary.light,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  iconButtonSizeSmall: {
    '& svg': {
      width: '1.05rem',
    },
  },
}));

const KeyboardDateField = ({ shrink, format, ...rest }) => {
  const [field, meta, form] = useField(rest);
  const classes = useStyles();

  return (
    <KeyboardDatePicker
      disableToolbar
      fullWidth
      format={format}
      inputVariant="outlined"
      variant="inline"
      InputLabelProps={{ shrink }}
      KeyboardButtonProps={{
        size: 'small',
        edge: 'end',
        classes: {
          root: classes.iconButtonRoot,
          sizeSmall: classes.iconButtonSizeSmall,
        },
      }}
      error={Boolean(meta.error)}
      helperText={meta.error}
      {...field}
      onChange={(date) =>
        form.setValue(date instanceof Date ? startOfDay(date) : null, false)
      }
      {...rest}
    />
  );
};

KeyboardDateField.propTypes = {
  label: PropTypes.string,
  format: PropTypes.string,
  shrink: PropTypes.bool,
};

KeyboardDateField.defaultProps = {
  shrink: true,
  format: 'dd.MM.yyyy',
};

export default KeyboardDateField;
