import React from 'react';

export const PasswordShowIcon = () => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C8.82931 6 6.05842 7.85584 4.54349 10.6244C4.41674 10.856 4.26009 11.1934 4.07354 11.6364L4.07356 11.6364C3.97574 11.8687 3.97497 12.1321 4.07141 12.365C4.21276 12.7063 4.33113 12.9697 4.42652 13.1552C5.91285 16.0452 8.74587 18 12 18C15.2355 18 18.0546 16.0676 19.5478 13.2049C19.6498 13.0092 19.7764 12.7297 19.9274 12.3665L19.9274 12.3665C20.0246 12.1327 20.0237 11.868 19.9249 11.6349C19.7235 11.1592 19.554 10.7983 19.4166 10.5521C17.8932 7.82359 15.1431 6 12 6ZM12 16C9.9735 16 8.32879 14.208 8.32879 12C8.32879 9.792 9.9735 8 12 8C14.0265 8 15.6712 9.792 15.6712 12C15.6712 14.208 14.0265 16 12 16ZM12 9.6C10.7812 9.6 9.79728 10.672 9.79728 12C9.79728 13.328 10.7812 14.4 12 14.4C13.2189 14.4 14.2027 13.328 14.2027 12C14.2027 10.672 13.2189 9.6 12 9.6Z"
      fill="currentColor"
    />
  </svg>
);
