import React from 'react';

export const ExploreIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9911 18.2022L5.44 13.1089L4 14.2289L12 20.4511L20 14.2289L18.5511 13.1L11.9911 18.2022ZM12 15.9444L18.5422 10.8511L20 9.72222L12 3.5L4 9.72222L5.44889 10.8511L12 15.9444Z"
      fill="currentColor"
    />
  </svg>
);
