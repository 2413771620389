import React from 'react';

import TableCellBase from './TableCellBase';
import TableCellBold from './TableCellBold';
import TableCellLink from './TableCellLink';
import ListHeadCell from '../ListHeadCell';

const cellRenderers = {
  base: (props) => <TableCellBase {...props} />,
  bold: (props) => <TableCellBold {...props} />,
  link: (props) => <TableCellLink {...props} />,
  listHead: (props) => <ListHeadCell {...props} />,
};

export default cellRenderers;
