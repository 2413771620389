import { useCallback, useState, useMemo, createContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Screens, { SCREENS } from './Screens';
import { parseToken } from './lib/token';
import { getFromStorage, TOKEN_KEY, USERNAME_KEY } from './lib/storage';
import { AuthenticatedRoutes, UnauthenticatedRoutes } from './routing/routes';
import { SnackbarProvider, ApiErrorProvider } from './components';
import { SomethingWentWrongScreen } from './screens/SomethingWentWrongScreen';

function getUserInfo() {
  const tokenParsed = parseToken(getFromStorage(TOKEN_KEY));
  const username = getFromStorage(USERNAME_KEY);

  return tokenParsed.error
    ? null
    : {
        ...tokenParsed,
        username,
      };
}

const userInfoInitial = getUserInfo();

const screenInfoInitial = {
  screen: null,
  props: null,
};

export const AppContext = createContext(null);
AppContext.displayName = 'AppContext';

function App({ children }) {
  const [userInfo, setUserInfo] = useState(userInfoInitial);
  const [currentScreenInfo, setCurrentScreenInfo] = useState(screenInfoInitial);
  const [disabledSidebar, setDisableSidebar] = useState(false);

  const showScreen = useCallback((screen, props) => {
    setCurrentScreenInfo({ screen, props });
  }, []);

  const hideScreen = useCallback(() => {
    setCurrentScreenInfo(screenInfoInitial);
  }, []);

  const contextValue = useMemo(
    () => ({
      userInfo,
      setUserInfo,
      setDisableSidebar,
      screens: {
        names: SCREENS,
        showScreen,
        hideScreen,
      },
    }),
    []
  );

  return (
    <AppContext.Provider value={contextValue}>
      <Router>
        <ApiErrorProvider>
          <ErrorBoundary fallback={<SomethingWentWrongScreen />}>
            <SnackbarProvider>
              <Screens
                screen={currentScreenInfo.screen}
                screenProps={currentScreenInfo.props}
              >
                {userInfo ? (
                  <AuthenticatedRoutes userInfo={userInfo} disabledSidebar={disabledSidebar}>
                    {children}
                  </AuthenticatedRoutes>
                ) : (
                  <UnauthenticatedRoutes />
                )}
              </Screens>
            </SnackbarProvider>
          </ErrorBoundary>
        </ApiErrorProvider>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
