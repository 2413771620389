import React from 'react';
import { TooltipEllipsis } from '@21vek/admin-front-components';

import { tableCellBaseModel } from '../../DraggableTable/models';

const TableCellBase = ({ value, classNameWrapped, tooltipValue }) => (
  <TooltipEllipsis
    className={classNameWrapped}
    title={tooltipValue || String(value)}
  >
    {value}
  </TooltipEllipsis>
);

TableCellBase.propTypes = tableCellBaseModel;

TableCellBase.defaultProps = {
  value: '',
  tooltipValue: '',
  className: '',
  classNameWrapped: '',
};

export default TableCellBase;
