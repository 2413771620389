import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginLeft: -theme.spacing(0.5),
    marginRight: -theme.spacing(0.5),
    position: 'relative',
    '&::before': {
      position: 'absolute',
      height: 1,
      backgroundColor: theme.palette.grey[300],
      content: '""',
      top: '0',
      left: theme.spacing(0.5),
      right: theme.spacing(0.5),
    },
    '& > *': {
      margin: theme.spacing(0.5),
      marginTop: 'unset',
    },
  },
}));

function FormButtonGroup({ className, children, ...props }) {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.buttonGroup, className)} {...props}>
      {children}
    </Box>
  );
}

FormButtonGroup.propTypes = {
  className: PropTypes.string,
};

export default FormButtonGroup;
