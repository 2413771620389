import PropTypes from 'prop-types';
import clsx from 'clsx';

import { InputTooltipIcon } from '../InputTooltipIcon';
import { InputLongLabel } from './InputLongLabel';
import styles from './Inputs.module.css';

const InputLabel = ({
  label,
  tooltip,
  required,
  inputRef,
  withLongLabelTooltip,
  className,
  contrastingLabel,
}) =>
  withLongLabelTooltip ? (
    <InputLongLabel
      label={label}
      tooltip={tooltip}
      required={required}
      inputRef={inputRef}
      className={className}
      contrastingLabel={contrastingLabel}
    />
  ) : (
    <div
      className={clsx(styles.inputLabel, className, {
        [styles.contrasting]: contrastingLabel,
      })}
    >
      {label}
      {required && <span className={styles.required}>&nbsp;*</span>}
      {tooltip && (
        <InputTooltipIcon className={styles.tooltip} text={tooltip} />
      )}
    </div>
  );

InputLabel.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  inputRef: PropTypes.object,
  withLongLabelTooltip: PropTypes.bool,
  className: PropTypes.string,
  contrastingLabel: PropTypes.bool,
};

export { InputLabel };
