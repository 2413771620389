import jwtDecode from 'jwt-decode';

export const parseToken = (token) => {
  try {
    const decoded = jwtDecode(token);

    return { token, ...decoded };
  } catch (error) {
    return { token, error };
  }
};
