import React from 'react';
import MaterialTableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';

import { useStyles } from './tableStyles';

const TableBody = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <MaterialTableBody
      className={className}
      classes={{ root: classes.tableBody }}
      component="div"
      {...props}
    />
  );
};

TableBody.propTypes = {
  className: PropTypes.string,
};

TableBody.defaultProps = {
  className: '',
};

export default TableBody;
