import { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

import { useLabelWidth } from '../hooks/useLabelWidth';
import { useIsOverflown } from '../hooks/useIsOverflown';
import { InputTooltipIcon } from '../InputTooltipIcon';
import styles from './Inputs.module.css';

const useMuiStyles = makeStyles(() => ({
  tooltip: { maxHeight: 102 },
}));

const InputLongLabel = ({
  label,
  inputRef,
  required,
  tooltip,
  className,
  contrastingLabel,
}) => {
  const muiStyles = useMuiStyles();
  const labelRef = useRef(null);
  const labelWidth = useLabelWidth(inputRef);
  const isOverflown = useIsOverflown(labelRef, labelWidth);

  return (
    <div
      className={clsx(styles.inputLabel, className, {
        [styles.contrasting]: contrastingLabel,
      })}
      style={{ maxWidth: labelWidth }}
    >
      <Tooltip
        classes={{ tooltip: muiStyles.tooltip }}
        title={isOverflown ? label : ''}
      >
        <div className={styles.nowrap} ref={labelRef}>
          {label}
        </div>
      </Tooltip>
      {required && <span className={styles.required}>&nbsp;*</span>}
      {tooltip && (
        <span className={styles.tooltip}>
          <InputTooltipIcon text={tooltip} />
        </span>
      )}
    </div>
  );
};

InputLongLabel.propTypes = {
  inputRef: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  contrastingLabel: PropTypes.bool,
};

export { InputLongLabel };
