import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';

import { PasswordHideIcon } from '../Icons/PasswordHide';
import { PasswordShowIcon } from '../Icons/PasswordShow';

const TYPES = {
  PASSWORD: 'password',
  TEXT: 'text',
};

export const PasswordInput = ({ ...props }) => {
  const [type, setType] = useState(TYPES.PASSWORD);
  const handleBtnClick = () =>
    setType(type === TYPES.PASSWORD ? TYPES.TEXT : TYPES.PASSWORD);

  return (
    <TextField
      fullWidth
      FormHelperTextProps={{}}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleBtnClick}
              edge="end"
              size="small"
            >
              {type === TYPES.TEXT ? (
                <PasswordHideIcon />
              ) : (
                <PasswordShowIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      type={type}
      {...props}
    />
  );
};
