import React, { forwardRef, memo, ReactNode } from 'react';
import { MenuItem, Checkbox, Typography } from '@material-ui/core';

import { useStyles } from './SelectList.styles';
import {
  RadioActiveIcon,
  RadioInactiveIcon,
  CheckboxActiveIcon,
  CheckboxInactiveIcon,
} from '../Icons';

interface SelectListOptionProps {
  value: string;
  checked: boolean;
  children: ReactNode;
  disabled?: boolean;
  required?: boolean;
  variant?: 'radio' | 'checkbox';
}

export const SelectListOption = forwardRef<
  HTMLLIElement,
  SelectListOptionProps
>(({ variant = 'checkbox', children, checked, ...rest }, ref) => {
  const classes = useStyles();

  return (
    <MenuItem ref={ref} classes={{ root: classes.listItem }} {...rest}>
      <Checkbox
        color="primary"
        checked={checked}
        classes={{ root: classes.option }}
        checkedIcon={
          variant === 'radio' ? <RadioActiveIcon /> : <CheckboxActiveIcon />
        }
        icon={
          variant === 'radio' ? <RadioInactiveIcon /> : <CheckboxInactiveIcon />
        }
      />
      <Typography classes={{ noWrap: classes.noWrap }} noWrap variant="body2">
        {children}
      </Typography>
    </MenuItem>
  );
});

SelectListOption.displayName = 'SelectListOption';

export default memo(SelectListOption);
