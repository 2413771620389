import React from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { ButtonLoading } from '@21vek/admin-front-components';
import { Link } from '@21vek/admin-front-components';
import { reloadPage } from '../../lib/navigation';
import { Header } from '../../components/Header';
import { useStyles } from './somethingWentWrongScreenStyles';

import swwImage from '../../assets/Sww.svg';

export const SomethingWentWrongScreen = () => {
  const classes = useStyles();

  return (
    <div className="page pageError">
      <Header withGradientLine={false} />

      <main className="pageError__content somethingWentWrong">
        <section className="imageSection">
          <img src={swwImage} alt="Что-то пошло не так" />
        </section>

        <Typography
          classes={{
            root: classes.heading,
          }}
          variant="h5"
        >
          Упс&hellip; Что-то пошло не так
        </Typography>

        <Typography
          classes={{
            root: classes.rootTypographyBody,
          }}
          variant="body1"
        >
          Возникла ошибка в обработке запроса. Попробуйте обновить страницу и повторить запрос.
           Если это не помогло, напишите нам в поддержку.
        </Typography>

        <Box mt="32px" mb="20px">
          <Button
            onClick={reloadPage}
            variant="contained"
            color="primary"
          >
            Обновить страницу
          </Button>
        </Box>

        <Link href="mailto:dev@21vek.by">
          Написать в поддержку
        </Link>
      </main>
    </div>
  );
};
