import { useEffect, useState } from 'react';

const useWindowSize = (onResize) => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
      if (onResize) onResize();
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [onResize]);

  return size;
};

export { useWindowSize };
