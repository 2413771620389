import React from 'react';

export const BulbIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.42857 20.2C9.42857 20.6 9.77143 21 10.2857 21H13.7143C14.2286 21 14.5714 20.6 14.5714 20.2V19.4H9.42857V20.2ZM12 5C8.65714 5 6 7.48 6 10.6C6 12.52 7.02857 14.2 8.57143 15.16V17C8.57143 17.4 8.91429 17.8 9.42857 17.8H14.5714C15.0857 17.8 15.4286 17.4 15.4286 17V15.16C16.9714 14.12 18 12.44 18 10.6C18 7.48 15.3429 5 12 5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7 0.999988C12.7 0.613388 12.3866 0.299988 12 0.299988C11.6134 0.299988 11.3 0.613388 11.3 0.999988V2.99999C11.3 3.38659 11.6134 3.69999 12 3.69999C12.3866 3.69999 12.7 3.38659 12.7 2.99999V0.999988ZM6.00507 2.00501C6.27843 1.73165 6.72165 1.73165 6.99502 2.00501L8.49502 3.50501C8.76838 3.77838 8.76838 4.2216 8.49502 4.49496C8.22165 4.76833 7.77843 4.76833 7.50507 4.49496L6.00507 2.99496C5.7317 2.7216 5.7317 2.27838 6.00507 2.00501ZM17.995 2.00501C17.7216 1.73165 17.2784 1.73165 17.0051 2.00501L15.5051 3.50501C15.2317 3.77838 15.2317 4.2216 15.5051 4.49496C15.7784 4.76833 16.2216 4.76833 16.495 4.49496L17.995 2.99496C18.2684 2.7216 18.2684 2.27838 17.995 2.00501Z"
      fill="currentColor"
    />
  </svg>
);
