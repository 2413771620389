import React from 'react';
import { Link } from '@material-ui/core';
import { TooltipEllipsis } from '@21vek/admin-front-components';
import clsx from 'clsx';

import { tableCellBaseModel } from '../../DraggableTable/models';

const handleLinkClick = (event) => {
  event.stopPropagation();
};

const TableCellLink = ({ value, classNameWrapped, className }) => {
  const styles = {};
  return (
    <TooltipEllipsis
      title={value}
      className={clsx(styles.tableLink, className)}
    >
      <Link
        href={value}
        onClick={handleLinkClick}
        className={classNameWrapped}
        target="_blank"
      >
        {value}
      </Link>
    </TooltipEllipsis>
  );
};

TableCellLink.propTypes = tableCellBaseModel;

TableCellLink.defaultProps = {
  value: '',
  className: '',
  classNameWrapped: '',
};

export default TableCellLink;
