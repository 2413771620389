import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  base: {
    width: '100%',
    height: '48px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    flex: 1,
    padding: '0 12px',
    color: theme.palette.grey[500],
  },
  divider: {
    margin: '0 12px',
    height: '1px',
    backgroundColor: theme.palette.grey[300],
  },
  iconAccount: {
    flex: '0 0 auto',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.grey[300]}`,
    '& svg': {
      transform: 'translate(-1px, -1px)',
    }
  },
  email: {
    flex: '1 1 auto',
    padding: '0 0.5rem',
  },
  iconLogout: {
    flex: '0 0 auto',
    width: '24px',
    height: '24px',
    marginLeft: 'auto',
    padding: 0
  },
}));
