import React from 'react';

export const NavigationIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#212121"
      d="m12,11.12c-0.488,0 -0.88,0.392 -0.88,0.88c0,0.488 0.392,0.88 0.88,0.88c0.488,0 0.88,-0.392 0.88,-0.88c0,-0.488 -0.392,-0.88 -0.88,-0.88zm0,-7.12c-4.416,0 -8,3.584 -8,8c0,4.416 3.584,8 8,8c4.416,0 8,-3.584 8,-8c0,-4.416 -3.584,-8 -8,-8zm1.752,9.752l-6.552,3.048l3.048,-6.552l6.552,-3.048l-3.048,6.552z"
    />
  </svg>
);
