import { Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './tableStyles';

const ListHeadCell = ({ value }) => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.tableListHeadCellText}
      variant="inherit"
      color="textPrimary"
      noWrap
    >
      {value}
    </Typography>
  );
};

ListHeadCell.propTypes = {
  value: PropTypes.string,
};

ListHeadCell.defaultProps = {
  value: '',
};

export default ListHeadCell;
