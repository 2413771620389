import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { LogoIcon } from '@21vek/admin-front-components';
import { ReactComponent as PanelTextIcon } from '../../assets/PanelTextGradient.svg';
import { useStyles } from './headerStyles';


export const Header = ({ withGradientLine }) => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      { withGradientLine ? <div className={`gradient ${classes.topLine}`} /> : null }
      <div className={clsx(classes.logoWrapper, {
        [`${classes.logoWrapper}__noGradientLine`]: !withGradientLine,
      })}>
        <LogoIcon />
      </div>
      <PanelTextIcon />
    </header>
  );
};

Header.propTypes = {
  withGradientLine: PropTypes.bool,
};

Header.defaultProps = {
  withGradientLine: true,
};
