import React, {
  useState,
  useRef,
  Children,
  isValidElement,
  cloneElement,
  useMemo,
  memo,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Link,
  MenuList,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { isEqual } from 'lodash/fp';
import { useControlled } from '@material-ui/core/utils';
import clsx from 'clsx';

import { PseudoSelect } from '../Inputs';
import { PlusIcon } from '../Icons';
import { ButtonWithIcon } from '../ButtonWithIcon';
import { VirtualizedMenuList } from '../VirtualizedMenuList';
import { useStyles } from './dropdownStyles';

const WIDTH = 352;
const DOUBLE_LINE_ITEM_HEIGHT = 40;
const PADDING = 8;

function DropdownSelector({
  children,
  className,
  buttonSize,
  disabled,
  helperText,
  icon,
  MenuProps,
  name,
  onOpen,
  onChange,
  onClose,
  onSave,
  title,
  tooltipText,
  defaultValue,
  value: valueProp,
  searchInput,
  listClassName,
  popoverClassName,
  helperTextClassName,
  isVirtualizedMenuList,
  autoApply,
  legacyCheckbox,
  resetOnClose,
  dropdownTriggerVariant,
  renderCustomDropdownTrigger,
  dropdownStyles,
  label,
  disableApplyButton,
  applyButtonText,
  popoverProps,
  singleSelect,
  width,
  listHeight,
  innerRef,
  description,
  ...props
}) {
  const [value, setValue] = useControlled({
    controlled: valueProp,
    default: defaultValue,
    name: 'DropdownSelector',
  });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const classes = useStyles();

  const valueSortedStringified = useMemo(
    () => JSON.stringify(value?.sort()),
    [value]
  );

  const defaultValueSortedStringified = useMemo(
    () => JSON.stringify(defaultValue?.sort()),
    [defaultValue]
  );

  const handleToggle = () => {
    onOpen?.();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = useCallback(() => {
    if (resetOnClose) {
      setValue([]);
    }
    setOpen(false);
    onClose?.();
  }, [onClose, resetOnClose, setValue]);

  useEffect(() => {
    if (open) {
      window.addEventListener('resize', handleClose);

      return () => window.removeEventListener('resize', handleClose);
    }
  }, [handleClose, open]);

  const handleSave = () => {
    onSave(value);
    if (resetOnClose) {
      setValue([]);
    }
    handleClose();
  };

  const childrenArray = Children.toArray(children);

  const updateValue = (itemIndex, itemValue) => {
    if (singleSelect) {
      return [itemValue];
    }

    const newValue = value.slice();

    if (itemIndex === -1) {
      newValue.push(itemValue);
    } else {
      newValue.splice(itemIndex, 1);
    }

    return newValue;
  };

  const handleItemClick = (child) => (event) => {
    const itemValue = child.props.value;
    const itemIndex = value.indexOf(itemValue);

    const newValue = updateValue(itemIndex, itemValue);

    if (child.props.onClick) {
      child.props.onClick(event);
    }

    setValue(newValue);

    if (onChange) {
      Object.defineProperty(event, 'target', {
        writable: true,
        value: {
          value: newValue,
          name,
          diff: child.props.value,
          checked: !child.props.checked,
        },
      });
      onChange(event);
    }

    if (singleSelect && autoApply) {
      handleClose();
    }
  };

  const items = childrenArray.map((child) => {
    if (!isValidElement(child)) {
      return null;
    }

    const isSelected = value?.some(isEqual(child.props.value));

    return cloneElement(child, {
      onClick: handleItemClick(child),
      selected: legacyCheckbox ? isSelected : !!child.props.checked,
      value: undefined,
      'data-value': child.props.value,
    });
  });

  const renderDropdownTrigger = () => {
    if (renderCustomDropdownTrigger) {
      return renderCustomDropdownTrigger({
        ref: anchorRef,
        onClick: handleToggle,
        open,
        label,
        value,
      });
    }

    if (dropdownTriggerVariant === 'link') {
      return (
        <Link
          ref={anchorRef}
          className={classes.link}
          onClick={handleToggle}
          variant="body2"
          underline="none"
          disabled={disabled}
        >
          {icon}
          {title}
        </Link>
      );
    }
    if (dropdownTriggerVariant === 'select') {
      return (
        <PseudoSelect
          description={description}
          ref={anchorRef}
          onClick={handleToggle}
          isOpen={open}
          label={label}
          value={title}
        />
      );
    }
    return (
      <ButtonWithIcon
        ref={anchorRef}
        color="primary"
        icon={icon}
        onClick={handleToggle}
        variant="outlined"
        disabled={disabled}
        size={buttonSize}
        toggled={open}
        {...props}
      >
        {title}
      </ButtonWithIcon>
    );
  };

  const isApplyButtonDisabled = () => {
    if (disableApplyButton !== undefined) {
      return disableApplyButton;
    }
    return (
      !value?.length || valueSortedStringified === defaultValueSortedStringified
    );
  };

  return (
    <Box ref={innerRef} className={className}>
      <Tooltip title={tooltipText} TransitionComponent={Fade}>
        {renderDropdownTrigger()}
      </Tooltip>
      <Popover
        className={popoverClassName}
        open={open}
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        TransitionComponent={Fade}
        key={`${open}`}
        PaperProps={{ style: { width: width || WIDTH } }}
        {...popoverProps}
      >
        <ClickAwayListener onClickAway={autoApply ? handleSave : handleClose}>
          <Box className={classes.menuWrapper}>
            {searchInput || null}

            {isVirtualizedMenuList ? (
              <VirtualizedMenuList
                height={listHeight}
                width={width || WIDTH}
                listClassName={clsx(classes.list, listClassName)}
                items={items}
              />
            ) : (
              <MenuList
                className={clsx(classes.list, listClassName)}
                style={{
                  maxHeight: DOUBLE_LINE_ITEM_HEIGHT * 6 + PADDING * 3,
                  overflowY: 'auto',
                  marginRight: '-4px',
                }}
              >
                {items}
              </MenuList>
            )}
            {helperText && (
              <Typography
                className={clsx(classes.helperText, helperTextClassName)}
                align="center"
                color="textSecondary"
                variant="body2"
              >
                {helperText}
              </Typography>
            )}
            {!autoApply && (
              <Button
                className={classes.saveButton}
                disabled={isApplyButtonDisabled()}
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleSave}
              >
                {applyButtonText}
              </Button>
            )}
          </Box>
        </ClickAwayListener>
      </Popover>
    </Box>
  );
}

DropdownSelector.propTypes = {
  onSave: PropTypes.func,
  title: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  MenuProps: PropTypes.any,
  value: PropTypes.array,
  defaultValue: PropTypes.array,
  tooltipText: PropTypes.string,
  helperText: PropTypes.string,
  buttonSize: PropTypes.oneOf(['medium', 'small']),
  icon: PropTypes.node,
  className: PropTypes.string,
  searchInput: PropTypes.node,
  listClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  helperTextClassName: PropTypes.string,
  isVirtualizedMenuList: PropTypes.bool,
  autoApply: PropTypes.bool,
  resetOnClose: PropTypes.bool,
  legacyCheckbox: PropTypes.bool,
  disableApplyButton: PropTypes.bool,
  dropdownTriggerVariant: PropTypes.oneOf(['button', 'link', 'select']),
  dropdownStyles: PropTypes.object,
  renderCustomDropdownTrigger: PropTypes.func,
  label: PropTypes.string,
  applyButtonText: PropTypes.string,
  popoverProps: PropTypes.object,
  width: PropTypes.number,
  listHeight: PropTypes.number,
  singleSelect: PropTypes.bool,
  description: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

DropdownSelector.defaultProps = {
  onSave: () => {},
  buttonSize: 'medium',
  icon: <PlusIcon />,
  tooltipText: '',
  defaultValue: [],
  disableApplyButton: undefined,
  isVirtualizedMenuList: false,
  autoApply: false,
  resetOnClose: true,
  legacyCheckbox: true,
  dropdownTriggerVariant: 'button',
  applyButtonText: 'Добавить',
};

export default memo(DropdownSelector);
