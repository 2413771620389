import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  base: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: 1,
  },
  sidebar: {
    flex: '0 0 auto',
    width: theme.sizes.mainSidebar.width,
    boxShadow: 'var(--box-shadow)',
    zIndex: 1,
  },
  disabled: {
    pointerEvents: 'none',
    userSelect: 'none',
    opacity: 0.5,
  },
  hidden: {
    visibility: 'hidden !important',
    width: 0,
  },
  sticky: {
    position: 'sticky',
    top: 0,
  },
  hideIconWrapper: {
    width: 0,
  },
  hideSidebarButton: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: '240px',
    bottom: '22px',
    position: 'fixed',
    zIndex: 1,
    visibility: 'visible',
    '& button': {
      marginTop: '-2px',
      height: '56px',
      width: '24px',
      color: theme.palette.grey[500],
      '&:hover': {
        color: theme.palette.primary.light,
      },
      '& span': {
        width: '16px',
      },
      '& svg': {
        transform: 'scale(-1, 1)',
      },
    },
  },
  collapsed: {
    opacity: 1,
    left: 0,
    '& button svg': {
      transform: 'none',
      marginLeft: '1px',
    },
  },
  navHeader: {
    position: 'relative',
    width: '100%',
    height: '36px',
    fontSize: '14px',
    paddingTop: '12px',
    textAlign: 'center',
    color: theme.palette.common.white,
    backgroundImage: 'var(--gradient)',
    '& svg': {
      height: '14px',
    },
  },
  navList: {
    margin: '10px 0',
  },
  navItem: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '36px',
  },
  navAdminPartTitle: {
    padding: '0px 12px',
  },
  navAdminPartIcon: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
    flex: '0 0 auto',
  },
  navLink: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignSelf: 'stretch',
    alignItems: 'center',
    minWidth: 0,
    padding: '0px 12px 0 44px',
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    transition: theme.transitions.create('color, background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover:not($navLinkSelected)': {
      backgroundColor: theme.palette.primary['08'],
      color: theme.palette.primary.light,
    },
  },
  navLinkSelected: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
  },
  main: {
    flex: '1 1 auto',
    minWidth: 0,
    backgroundColor: theme.palette.grey[300],
    padding: '0.5rem',
  },
}));
