import React, { useState, useCallback, useRef, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import clsx from 'clsx';

import { CrossIcon } from '../Icons';
import { useStyles } from './inputStyles';

export const SearchInput = ({
  handleOnChangeCallback,
  isLoading,
  initialValue,
  helperText,
  integerOnly,
  maxLength,
  minLength,
  focusOnRender,
  ...props
}) => {
  const inputRef = useRef();
  const classes = useStyles();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (focusOnRender) {
      inputRef.current?.focus();
    }
  }, [isLoading]);

  const debounceFunc = useCallback(
    _.debounce((targetValue) => {
      handleOnChangeCallback(targetValue);
    }, 300),
    [handleOnChangeCallback]
  );

  const handleBtnClick = () => {
    handleOnChangeCallback(undefined);
    setValue('');
  };

  const handleOnChange = (event) => {
    let newValue = event.target.value;

    if (integerOnly) {
      newValue = newValue.replace(/\D/g, '');
    }

    if (maxLength) {
      newValue = newValue.slice(0, maxLength);
    }

    setValue(newValue);

    if (minLength) {
      if (newValue.length >= minLength) {
        debounceFunc(newValue);
      } else {
        handleOnChangeCallback(undefined);
      }
    } else {
      debounceFunc(newValue);
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextField
      autoFocus={focusOnRender}
      inputRef={inputRef}
      value={value}
      onChange={handleOnChange}
      fullWidth
      InputProps={{
        endAdornment:
          value || isLoading ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="reset field"
                onClick={handleBtnClick}
                edge="end"
                size="small"
              >
                {isLoading ? (
                  <CircularProgress
                    classes={{ root: classes.rootCircular }}
                    size={12}
                  />
                ) : (
                  <CrossIcon />
                )}
              </IconButton>
            </InputAdornment>
          ) : null,
        classes: {
          root: clsx(classes.rootInput, classes.searchInput),
        },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      onKeyDown={(event) => event.stopPropagation()}
      helperText={helperText}
      {...props}
    />
  );
};

SearchInput.propTypes = {
  focusOnRender: PropTypes.bool,
  handleOnChangeCallback: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  initialValue: PropTypes.string,
  helperText: PropTypes.string,
  integerOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
};

SearchInput.defaultProps = {
  initialValue: '',
};

export default SearchInput;
