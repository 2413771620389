import { FC, forwardRef } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { IPropsButtonWithIcon } from '@21vek/admin-front-components';

import { useStyles } from './buttonWithIconStyles';

/**
 *   Кнопка с иконкой, реализованна на базе
 *   <a href="https://material-ui.com/ru/components/buttons/#button">Button из material</a>,
 *   можно использовать все те же пропсы что и у Button
 */
export const ButtonWithIcon: FC<IPropsButtonWithIcon> = forwardRef<
  HTMLButtonElement,
  IPropsButtonWithIcon
>(
  (
    {
      children,
      iconAlign = 'start',
      icon,
      toggled,
      classes: customClasses = {},
      ...muiButtonProps
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <Button
        ref={ref}
        className={clsx({ [classes.toggled]: toggled })}
        classes={{
          root: classes.root,
          startIcon: classes.startIcon,
          endIcon: classes.endIcon,
          outlined: classes.outlined,
          outlinedSizeSmall: classes.outlinedSizeSmall,
          containedSizeSmall: classes.containedSizeSmall,
          ...customClasses,
        }}
        startIcon={iconAlign === 'start' && icon ? icon : undefined}
        endIcon={iconAlign === 'end' && icon ? icon : undefined}
        {...muiButtonProps}
      >
        {children}
      </Button>
    );
  }
);
