import { useContext, useState } from 'react';
import { Typography, IconButton, Tooltip } from '@material-ui/core';
import {
  AccountIcon,
  LogoutIcon,
  ConfirmDialog,
} from '@21vek/admin-front-components';
import { AppContext } from '../../App';
import { useStyles } from './userInfoBlockStyles';
import { logoutRequest } from '../../lib/api';
import { removeFromStorage, TOKEN_KEY, USERNAME_KEY } from '../../lib/storage';
import { removeFromSessionStorage, UNAUTHORIZED_USER_URL } from '../../lib/sessionStorage';
import { useApiError } from '../../components';

export const UserInfoBlock = () => {
  const classes = useStyles();
  const { userInfo, setUserInfo } = useContext(AppContext);
  const { setErrorStatusCode } = useApiError();
  const [open, setOpen] = useState(false);
  const [logoutRequestPending, setLogoutRequestPending] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExitClick = async () => {
    try {
      setLogoutRequestPending(true);
      await logoutRequest();

      removeFromStorage(TOKEN_KEY);
      removeFromStorage(USERNAME_KEY);
      setUserInfo(null);
      removeFromSessionStorage(UNAUTHORIZED_USER_URL);
    } catch (e) {
      setLogoutRequestPending(false);
      setErrorStatusCode(e.statusCode);
    }
  };

  return (
    <>
      <div className={classes.base}>
        <span className={classes.iconAccount}>
          <AccountIcon />
        </span>

        <Typography
          variant="body1"
          noWrap
          component="span"
          className={classes.email}
        >
          {userInfo.username ?? userInfo.user_id}
        </Typography>

        <Tooltip title="Выход из админки">
          <IconButton
            className={classes.iconLogout}
            onClick={handleClickOpen}
            size="small"
            color="primary"
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </div>

      <ConfirmDialog
        open={open}
        loading={logoutRequestPending}
        onClose={handleClose}
        onConfirm={handleExitClick}
        title="Выход из админки"
        description="Вы уверены, что хотите выйти из панели администратора?"
        confirmBtnCaption="Выйти"
      />

      <div className={classes.divider} />
    </>
  );
};
