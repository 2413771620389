import { TableCell as MaterialTableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { useStyles } from './tableStyles';

export const FakeActionsCell = ({ actions }) => {
  const styles = useStyles();
  return (
    <MaterialTableCell
      className={styles.actionsCell}
      classes={{ root: styles.tableCell, head: styles.tableCellHead }}
      component="div"
    >
      {actions.map(({ isHidden }, i) =>
        isHidden && isHidden() ? null : (
          <div key={i} className={styles.actionIconButton} />
        )
      )}
    </MaterialTableCell>
  );
};

FakeActionsCell.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.any),
};

export default FakeActionsCell;
