import { makeStyles } from '@material-ui/core/styles';

const ITEM_HEIGHT = 32;

export const useStyles = makeStyles((theme) => {
  const iconStyles = {
    content: '""',
    pointerEvents: ({ clearable }) => (clearable ? 'auto' : 'none'),
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    position: 'absolute',
    top: '18px',
    right: '16px',
  };

  const labelShrinkStyles = {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      'font-size': '12px',
      transform: 'translate(14px, -5px)',
    },
  };

  const triangleBorder = `5px solid ${theme.palette.primary.light}`;

  return {
    autocompleteListbox: {
      maxHeight: ({ items }) => (items ? ITEM_HEIGHT * items + 8 : 'initial'),
    },
    autocompleteNoOptions: {
      display: 'none',
    },
    multilineInput: {
      '& .MuiOutlinedInput-multiline': {
        padding: '9px 3px 12px 0',
      },
      '& .MuiOutlinedInput-inputMultiline': {
        padding: '0 9px 0 12px',
      },
      '& .MuiOutlinedInput-inputMultiline::-webkit-scrollbar': {
        width: '4px',
      },
      '& .MuiOutlinedInput-inputMultiline::-webkit-scrollbar-thumb': {
        background: theme.palette.grey[400],
        borderRadius: '4px',
      },
      '& .MuiOutlinedInput-inputMultiline::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.grey[500],
      },
      ...labelShrinkStyles,
    },
    rootInput: {
      ' & fieldset': {
        borderRadius: '4px',
      },
    },
    searchInput: {
      background: 'var(--white)',
    },
    rootCircular: {
      marginRight: '6px',
    },
    pseudoSelectOpen: {
      width: '100%',
      '&::after': {
        ...iconStyles,
        borderBottom: ({ hideTriangle }) =>
          hideTriangle ? undefined : triangleBorder,
      },
      ...labelShrinkStyles,
    },
    pseudoSelectClosed: {
      width: '100%',
      '&::after': {
        ...iconStyles,
        borderTop: ({ hideTriangle }) =>
          hideTriangle ? undefined : triangleBorder,
      },
      ...labelShrinkStyles,
    },
    pseudoSelectInput: {
      boxSizing: 'inherit',
      position: 'relative',
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      paddingRight: '32px',
      height: '40px',
      width: '100%',
      '&.Mui-disabled': {
        cursor: 'default',
        color: theme.palette.grey[500],
        background: theme.palette.grey[100],
      },
    },
    pseudoSelectOutline: {
      borderColor: `${theme.palette.grey[300]} !important`,
    },
    pseudoSelectErrorOutline: {
      borderColor: `${theme.palette.secondary.light} !important`,
    },
  };
});
