import React from 'react';

export const DeleteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.77778 17.4444C7.77778 18.3 8.47778 19 9.33333 19H15.5556C16.4111 19 17.1111 18.3 17.1111 17.4444V8.11111L7.77778 8.11111L7.77778 17.4444ZM9.33333 9.66667L15.5556 9.66667L15.5556 17.4444H9.33333L9.33333 9.66667ZM15.1667 5.77778L14.3889 5H10.5L9.72222 5.77778H7V7.33333L17.8889 7.33333V5.77778H15.1667Z"
      fill="currentColor"
    />
  </svg>
);
