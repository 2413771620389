import React, { FC, ReactNode, CSSProperties } from 'react';
import { FixedSizeList } from 'react-window';

const ITEM_HEIGHT = 32;
const MAX_HEIGHT = ITEM_HEIGHT * 7;

interface VirtualizedMenuListProps {
  items: ReactNode[];
  width: number | string;
  height?: number;
  listClassName?: string;
}

const VirtualizedMenuList: FC<VirtualizedMenuListProps> = ({
  listClassName,
  items = [],
  width,
  height = MAX_HEIGHT,
}) => {
  const virtualizedRow = ({
    index,
    style,
  }: {
    index: number;
    style: CSSProperties;
  }) => (
    <div style={style} key={index}>
      {items[index]}
    </div>
  );

  return (
    <FixedSizeList
      width={width}
      height={Number.isInteger(height) ? height : MAX_HEIGHT}
      className={listClassName}
      itemSize={ITEM_HEIGHT}
      itemCount={items.length}
    >
      {virtualizedRow}
    </FixedSizeList>
  );
};

VirtualizedMenuList.displayName = 'VirtualizedMenuList';

export default VirtualizedMenuList;
