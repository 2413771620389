import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { getAdminPartRoute } from '../lib/navigation';


export const AdminLink = ({ to, className, activeClassName, children, ...props }) => {
  const currentPartRoute = getAdminPartRoute();
  let pathname;

  if (typeof to === 'string') {
    pathname = to;
  } else if (typeof to === 'object') {
    pathname = to.pathname;
  }

  const isTheSameAdminPart = pathname.includes(currentPartRoute);

  return isTheSameAdminPart
    ? (
      <NavLink
        to={to}
        exact
        className={clsx(className)}
        activeClassName={clsx(activeClassName)}
        {...props}
      >
        {children}
      </NavLink>
    )
    : <a href={pathname} className={clsx(className)}>{children}</a>
};

AdminLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
};
