import React from 'react';
import PropTypes from 'prop-types';
import { Link as MaterialLink } from '@material-ui/core';

import { useStyles } from './linkStyles';

export const Link = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <MaterialLink
      classes={{
        root: classes.root,
      }}
      underline="none"
      variant="body1"
      {...props}
    >
      {children}
    </MaterialLink>
  );
};

Link.propTypes = {
  children: PropTypes.node,
};
