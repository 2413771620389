import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography, Fade, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  tooltip: {
    maxHeight: 'unset',
  },
});

function TooltipEllipsis({ title, children, TypographyProps, ...props }) {
  const [hover, setHover] = useState(false);
  const textRef = useRef();
  const classes = useStyles();

  const compare = useCallback(() => {
    setHover(textRef.current.scrollWidth > textRef.current.clientWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', compare);
    compare();

    return () => {
      window.removeEventListener('resize', compare);
    };
  }, [compare]);

  return (
    <Tooltip
      title={title ?? children}
      TransitionComponent={Fade}
      disableHoverListener={!hover}
      classes={{ tooltip: classes.tooltip }}
      {...props}
    >
      <Typography ref={textRef} noWrap {...TypographyProps}>
        {children}
      </Typography>
    </Tooltip>
  );
}

TooltipEllipsis.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  TypographyProps: PropTypes.any,
};

export default TooltipEllipsis;
