import React from 'react';

export const CrossIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.03044 6.96967C7.73755 6.67678 7.26267 6.67678 6.96978 6.96967C6.67689 7.26257 6.67689 7.73744 6.96978 8.03033L10.9394 12L6.96978 15.9697C6.67689 16.2626 6.67689 16.7374 6.96978 17.0303C7.26267 17.3232 7.73755 17.3232 8.03044 17.0303L12.0001 13.0607L15.9698 17.0303C16.2627 17.3232 16.7375 17.3232 17.0304 17.0303C17.3233 16.7374 17.3233 16.2626 17.0304 15.9697L13.0608 12L17.0304 8.03033C17.3233 7.73743 17.3233 7.26256 17.0304 6.96967C16.7375 6.67677 16.2627 6.67677 15.9698 6.96967L12.0001 10.9393L8.03044 6.96967Z"
      fill="currentColor"
    />
  </svg>
);
