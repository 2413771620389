import PropTypes from 'prop-types';

export const tableCellBaseModel = {
  value: PropTypes.any,
  tooltipValue: PropTypes.string,
  classNameWrapped: PropTypes.string,
  className: PropTypes.string,
};

export const tableCellModel = {
  value: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  renderer: PropTypes.func,
  className: PropTypes.string,
};
