import React from 'react';

export const PlusIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3 12.7L11.3 17H12.7L12.7 12.7L17 12.7V11.3L12.7 11.3V7H11.3V11.3H7V12.7H11.3Z"
      fill="currentColor"
    />
  </svg>
);
