const STORAGE = window.sessionStorage;

export const UNAUTHORIZED_USER_URL = 'unauthorized-user-url';

export function saveToSessionStorage(key, value) {
  STORAGE.setItem(key, value);
}

export function getFromSessionStorage(key) {
  return STORAGE.getItem(key);
}

export function removeFromSessionStorage(key) {
  STORAGE.removeItem(key);
}
