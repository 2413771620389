import { FC, useRef } from 'react';
import { Tooltip, makeStyles, Theme } from '@material-ui/core';
import {
  useIsOverflown,
  TooltipInputProps,
  TooltipInputStylesProps,
} from '@21vek/admin-front-components';

import { Input } from './Input.js';
import styles from './Inputs.module.css';

const useMuiStyles = makeStyles<Theme, TooltipInputStylesProps>(() => ({
  tooltip: {
    maxHeight: 102,
    overflow: ({ withEllipsis }) => (withEllipsis ? 'hidden' : undefined),
    display: ({ withEllipsis }) => (withEllipsis ? '-webkit-box' : undefined),
    textOverflow: ({ withEllipsis }) => (withEllipsis ? 'ellipsis' : undefined),
    '-webkit-line-clamp': ({ withEllipsis }) => (withEllipsis ? 6 : undefined),
    '-webkit-box-orient': ({ withEllipsis }) =>
      withEllipsis ? 'vertical' : undefined,
  },
}));

export const TooltipInput: FC<TooltipInputProps> = ({
  value = '',
  withEllipsis,
  innerRef,
  ...props
}) => {
  const muiStyles = useMuiStyles({ withEllipsis });
  const inputRef = useRef<HTMLInputElement>(null);
  const isOverflown = useIsOverflown(inputRef, value);

  return (
    <Tooltip
      classes={{ tooltip: muiStyles.tooltip }}
      title={isOverflown ? value : ''}
    >
      <Input
        ref={innerRef}
        inputProps={{ className: styles.tooltipInput }}
        inputRef={inputRef}
        withLongLabelTooltip
        {...props}
      />
    </Tooltip>
  );
};
