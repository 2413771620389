export function goToMainPage() {
  window.location.href = '/';
}

export function goToPage(pagePathName) {
  window.location.href = pagePathName;
}

export function reloadPage() {
  window.location.reload();
}

export function getAdminPartRoute() {
  const path = window.location.pathname;

  if (path === '/') {
    return path;
  }

  const endIndex =  path.indexOf('/', 1) !== -1 ? path.indexOf('/', 1) : path.length;
  return path.substring(0, endIndex);
}

export function isItAdminPartMainRoute() {
  let path = window.location.pathname;

  if (path.endsWith('/')) {
    path = path.slice(0, -1);
  }

  return path.indexOf('/', 1) === -1;
}
