import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '@21vek/admin-front-components';
import App from './src/App';
import { request, ResponseError } from './src/lib/api';
import { getFromStorage, TOKEN_KEY } from './src/lib/storage';
import { delay, MIN_REQUEST_TIME_MS, copyToClipboard } from './src/lib/helpers';

import '@21vek/admin-front-components/src/theme.css';
import './src/index.css';

export * from './src/hooks';
export * from './src/components';
export { ROUTES } from './src/routing/routes';
export { AppContext } from './src/App';
export { NotFound } from './src/screens/NotFoundScreen';
export { SomethingWentWrong } from './src/screens/SomethingWentWrongScreen';

export const api = {
  request,
  ResponseError,
};

export const storage = {
  getFromStorage,
  TOKEN_KEY,
};

export const helpers = {
  delay,
  copyToClipboard,
  MIN_REQUEST_TIME_MS,
};

export default ({ children }) => (
  <ThemeProvider theme={theme}>
    <App>{children}</App>
  </ThemeProvider>
);
