import { Descendant } from 'slate';

import { createEmptyParagraph, createEmptyText } from './text';
import {
  COLORS,
  LARGE_SIZE,
  LARGE_SIZE_VALUE,
  TEXT_ALIGNS,
  WARNING_BANNERS,
} from '../TextEditor.constants';
import {
  CustomElement,
  CustomText,
  FontColor,
  LinkElement,
  Marks,
  TextAlign,
  WarningBanners,
} from '../TextEditor.types';
import { isListType } from './block';

const LEAF_TAGS = new Set(['B', 'I', 'U', 'S', 'SPAN']);

const MARKS_BY_TAG: Record<string, keyof CustomText> = {
  B: 'bold',
  I: 'italic',
  U: 'underline',
  S: 'strikethrough',
};

const AVAILABLE_COLORS: Record<string, FontColor> = {
  [COLORS.grey]: 'grey',
  [COLORS.red]: 'red',
  [COLORS.black]: 'black',
  '#a6a6a6': 'grey',
};

const ELEMENTS_BY_TAG: Record<string, CustomElement['type']> = {
  LI: 'list-item',
  UL: 'bulleted-list',
  OL: 'numbered-list',
  A: 'link',
  P: 'paragraph',
};

const deserializeElementAttributes = (
  node: HTMLElement
): Partial<Descendant> => {
  const element: Partial<CustomElement> = {};
  const textAlign = node.style.textAlign as TextAlign;

  if (node.style.textAlign && TEXT_ALIGNS.includes(textAlign)) {
    element.align = textAlign;
  }

  if (node.tagName === 'A' && node.getAttribute('href')) {
    (element as LinkElement).url = node.getAttribute('href') || '';
  }

  return element;
};

export const getBannerType = (
  element: HTMLElement
): WarningBanners | undefined => {
  if (element.innerHTML.includes(WARNING_BANNERS.title1)) {
    return 'warning-banner-1';
  }

  if (element.innerHTML.includes(WARNING_BANNERS.title2)) {
    return 'warning-banner-2';
  }

  return undefined;
};

export const deserializeBanner = (
  element: HTMLElement
): Descendant[] | null => {
  const bannerType = getBannerType(element);

  if (!bannerType) return null;

  const contentElement = element.getElementsByTagName('div')[0];

  return [
    {
      type: bannerType,
      children: deserializeChildNodes(contentElement.childNodes),
    },
  ];
};

const deserializeElement = (element: HTMLElement): Descendant[] | null => {
  const elementType = ELEMENTS_BY_TAG[element.tagName];

  const attributes = deserializeElementAttributes(element);
  const children = deserializeChildNodes(element.childNodes, elementType);

  if (
    elementType === 'link' &&
    (!(attributes as Partial<LinkElement>).url || children.length === 0)
  )
    return null;

  if (children.length === 0) children.push(createEmptyText());

  return [{ type: elementType, children, ...attributes }];
};

const deserializeLeafNodes = (
  childNodes: NodeListOf<ChildNode>,
  marks: Marks
): CustomText[] => {
  return Array.from(childNodes)
    .reduce(
      (result, childNode) =>
        result.concat(deserializeLeaf(childNode as HTMLElement, marks)),
      new Array<CustomText | null>()
    )
    .filter((node) => !!node) as CustomText[];
};

const deserializeLeafStyles = (style: CSSStyleDeclaration): Marks => {
  const marks: Marks = {};

  if (Number.parseInt(style.fontSize, 10) >= LARGE_SIZE_VALUE) {
    marks.fontSize = LARGE_SIZE;
  }

  if (AVAILABLE_COLORS[style.color]) {
    marks.color = AVAILABLE_COLORS[style.color];
  }

  return marks;
};

const deserializeLeaf = (
  element: HTMLElement,
  marks: Marks = {}
): CustomText[] | null => {
  if (element.innerHTML === '') {
    return null;
  }

  if (element.tagName === 'SPAN') {
    const updatedMarks = { ...marks, ...deserializeLeafStyles(element.style) };
    return deserializeLeafNodes(element.childNodes, updatedMarks);
  }

  const tagMark = MARKS_BY_TAG[element.tagName];

  if (tagMark) {
    const updatedMarks = { ...marks, [tagMark]: true };

    if (element.childNodes.length) {
      return deserializeLeafNodes(element.childNodes, updatedMarks);
    }
  }

  return [{ text: element.textContent || '', ...marks }];
};

const wrapInlineElements = (
  elements: Descendant[],
  parentType?: CustomElement['type']
): Descendant[] | null => {
  if (parentType && ['paragraph', 'list-item', 'link'].includes(parentType))
    return elements;

  const wrapper = createEmptyParagraph();
  wrapper.children = elements;

  return [wrapper];
};

const deserializeBr = (
  parentType?: CustomElement['type']
): Descendant[] | null => {
  if (parentType === 'paragraph') {
    return [{ text: '\n' }];
  }

  return [createEmptyParagraph()];
};

const deserializeNode = (
  node: ChildNode,
  parentType?: CustomElement['type']
): Descendant[] | null => {
  if (isListType(parentType) && (node as HTMLElement).tagName !== 'LI')
    return null;

  if (node.nodeType === Node.TEXT_NODE) {
    const textNode = [{ text: node.textContent || '' }];
    return wrapInlineElements(textNode, parentType);
  }

  if (node.nodeType === Node.ELEMENT_NODE) {
    const element = node as HTMLElement;
    const { tagName } = element;

    if (tagName === 'DIV') {
      return deserializeBanner(element);
    }

    if (tagName === 'BR') {
      return deserializeBr(parentType);
    }

    if (LEAF_TAGS.has(tagName)) {
      const deserializedLeaf = deserializeLeaf(element);
      return deserializedLeaf?.length
        ? wrapInlineElements(deserializedLeaf, parentType)
        : null;
    }

    if (ELEMENTS_BY_TAG[tagName]) {
      if (tagName === 'A') {
        const deserializedElements = deserializeElement(element);
        return deserializedElements?.length
          ? wrapInlineElements(deserializedElements, parentType)
          : null;
      }
      return deserializeElement(element);
    }
  }

  return null;
};

const deserializeChildNodes = (
  childNodes: NodeListOf<ChildNode>,
  parentType?: CustomElement['type']
): Descendant[] => {
  return Array.from(childNodes).reduce((result, node) => {
    const deserializedNode = deserializeNode(node, parentType);

    if (deserializedNode) {
      result.push(...deserializedNode);
    }

    return result;
  }, new Array<Descendant>());
};

export const deserialize = (htmlString: string): Descendant[] => {
  const htmlElement = new DOMParser().parseFromString(
    htmlString,
    'text/html'
  ).body;
  const deserializedNodes = deserializeChildNodes(htmlElement.childNodes);

  if (!deserializedNodes.length) return [createEmptyParagraph()];

  return deserializedNodes;
};
