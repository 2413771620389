import React from 'react';

export const FinanceIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 7H20.5V16H7V7ZM13.75 9.25C14.3467 9.25 14.919 9.48705 15.341 9.90901C15.7629 10.331 16 10.9033 16 11.5C16 12.0967 15.7629 12.669 15.341 13.091C14.919 13.5129 14.3467 13.75 13.75 13.75C13.1533 13.75 12.581 13.5129 12.159 13.091C11.7371 12.669 11.5 12.0967 11.5 11.5C11.5 10.9033 11.7371 10.331 12.159 9.90901C12.581 9.48705 13.1533 9.25 13.75 9.25ZM10 8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89783 10 8.5 10V13C8.89783 13 9.27936 13.158 9.56066 13.4393C9.84196 13.7206 10 14.1022 10 14.5H17.5C17.5 14.1022 17.658 13.7206 17.9393 13.4393C18.2206 13.158 18.6022 13 19 13V10C18.6022 10 18.2206 9.84196 17.9393 9.56066C17.658 9.27936 17.5 8.89782 17.5 8.5H10ZM4 10H5.5V17.5H17.5V19H4V10Z"
      fill="currentColor"
    />
  </svg>
);
