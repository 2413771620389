import { getFromStorage, TOKEN_KEY } from './storage';

const GATE_URL = process.env.REACT_APP_API_GATEWAY;
const defaultHeaders = {
  accept: 'application/json',
  'content-type': 'application/json',
};

export class ResponseError {
  constructor({ statusCode, statusText, errors }) {
    this.statusCode = statusCode;
    this.statusText = statusText;
    this.errors = errors;
  }
}

export async function loginRequest({ login, password }) {
  return request({
    url: '/auth/admin/login',
    body: JSON.stringify({ login, password }),
  });
}

export async function logoutRequest() {
  const token = getFromStorage(TOKEN_KEY);

  return request({
    url: '/auth/admin/logout',
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
}

function handleJSONResponse(response) {
  return response.json().then((json) => {
    if (response.ok) {
      return json;
    } else {
      return Promise.reject(
        new ResponseError({
          statusCode: response.status,
          statusText: response.statusText,
          errors: json?.errors ?? [],
        })
      );
    }
  });
}

function handleTextResponse(response) {
  return response.text().then((text) => {
    if (response.ok) {
      return text;
    } else {
      return Promise.reject(
        new ResponseError({
          statusCode: response.status,
          statusText: response.statusText,
          errors: text,
        })
      );
    }
  });
}

export async function request({ url, method = 'POST', headers, body }) {
  const urlFull = `${GATE_URL}${url}`;
  const response = await window.fetch(urlFull, {
    method,
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    body,
  });

  if (response.status === 204) {
    return {};
  }

  const contentType = response.headers.get('content-type');

  if (contentType.includes('application/json')) {
    return handleJSONResponse(response);
  } else if (contentType.includes('text/html')) {
    return handleTextResponse(response);
  }
}
