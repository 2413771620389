import React from 'react';
import PropTypes from 'prop-types';

export const logoIconColors = {
  currentColor: 'currentColor',
  gradient: 'url(#paint0_linear)',
};

export const LogoIcon = ({ color }) => (
  <svg
    width="71"
    height="16"
    viewBox="0 0 71 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.48923 0C7.55372 0 8.92497 1.86088 8.92497 3.80158C8.92497 5.71279 7.67398 7.02109 6.13329 8.42358L4.34313 9.93537H8.90631V10.0217V10.7482V12.6695H0V10.2952L3.94934 6.79495C5.681 5.15553 5.98899 4.73489 5.98899 3.92094C5.98899 3.336 5.61172 2.56626 4.46195 2.56626C3.00203 2.56626 2.92198 3.92741 2.92198 4.37861L2.91875 4.4293H0.0362556V4.37465C0.0362556 1.75806 1.82642 0 4.48923 0ZM42.0546 3.32126L39.0113 7.14045H38.942V0.388285H36.141V12.7019H38.942V8.57926H39.0023L42.0004 12.7019H45.3711L41.7904 7.8257L45.472 3.32126H42.0546ZM11.4153 2.9584H9.73606V0.388545H14.3219V12.7018H11.4153V2.9584ZM20.452 9.4035L22.7333 3.32112H25.7393L21.9141 12.7017H18.6662L15.0162 3.32112H18.0398L20.3803 9.4035H20.452ZM65.4906 9.29061L67.7312 3.32112H70.7368L65.6607 16H62.6544L63.8878 13.0908L60.0142 3.32112H63.0374L65.4191 9.29061H65.4906ZM30.1759 3.00003C27.3641 3.00003 25.0843 5.15032 25.0843 8.00348C25.0843 10.857 27.3641 13.0059 30.1759 13.0059C32.3598 13.0059 34.2157 11.7091 34.9397 9.7939H31.9054C31.5655 10.3008 30.8533 10.5536 30.1759 10.5536C29.0793 10.5536 28.2565 9.90463 27.9176 8.98282H32.4198H34.2789H35.1727C35.2334 8.66752 35.2689 8.34107 35.2689 8.00348C35.2689 5.16434 32.9888 3.00003 30.1759 3.00003ZM27.9162 7.02235C28.2526 6.10054 29.071 5.45232 30.1759 5.45232C31.3249 5.45232 32.2238 6.05955 32.4446 7.02235H27.9162ZM46.2611 9.82847H49.1691V12.7018H46.2611V9.82847ZM55.9547 2.94915C54.9116 3.0171 53.9582 3.423 53.2941 4.04677H53.2316V0.388285H50.417V12.6997H53.1645V11.9505H53.2431C53.876 12.5721 54.9475 12.9665 55.9547 13.006C58.7087 13.1128 60.7652 10.846 60.7652 7.97777C60.7652 5.12533 58.7385 2.76724 55.9547 2.94915ZM55.557 10.5412C54.2274 10.5412 53.148 9.39321 53.148 7.97741C53.148 6.56126 54.2274 5.41367 55.557 5.41367C56.8888 5.41367 57.9671 6.56126 57.9671 7.97741C57.9671 9.39321 56.8888 10.5412 55.557 10.5412Z"
      fill={logoIconColors[color] || 'url(#paint0_linear)'}
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="-1.81174e-07"
        y1="8.00001"
        x2="70.7368"
        y2="8.00001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#941E94" />
        <stop offset="1" stopColor="#E52E6B" />
      </linearGradient>
    </defs>
  </svg>
);

LogoIcon.propTypes = {
  color: PropTypes.oneOf(Object.values(logoIconColors)),
};

LogoIcon.defaultProps = {
  color: logoIconColors.gradient,
};
