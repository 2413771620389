import React, { useContext } from 'react';
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { Input } from '@21vek/admin-front-components';
import { PasswordInput } from '@21vek/admin-front-components';
import { ButtonLoading } from '@21vek/admin-front-components';
import { Link } from '@21vek/admin-front-components';
import { loginRequest, ResponseError } from '../../lib/api';
import { saveToStorage, TOKEN_KEY, USERNAME_KEY } from '../../lib/storage';
import { parseToken } from '../../lib/token';
import { AppContext } from '../../App';

import './LoginScreen.css';

const FormRow = ({ children }) => <Box my="2rem">{children}</Box>;

export const LoginForm = () => {
  const {
    screens: { names: screenNames, showScreen },
    setUserInfo,
  } = useContext(AppContext);

  return (
    <Formik
      initialValues={{ login: '', password: '' }}
      validateOnBlur={false}
      validateOnChange={false}
      validate={(values) => {
        const errors = {};

        if (!values.login) {
          errors.login = 'Обязательное поле для заполнения';
        }

        if (!values.password) {
          errors.password = 'Обязательное поле для заполнения';
        }

        return errors;
      }}
      onSubmit={async (values, { setFieldError }) => {
        try {
          const response = await loginRequest({
            login: values.login,
            password: values.password,
          });

          saveToStorage(TOKEN_KEY, response.data.token);
          saveToStorage(USERNAME_KEY, values.login);

          setUserInfo({
            ...parseToken(response.data.token),
            username: values.login,
          });
        } catch (err) {
          if (err instanceof ResponseError && err.statusCode === 401) {
            setFieldError('login', 'Ошибка авторизации');
            setFieldError('password', 'Ошибка авторизации');
          } else {
            showScreen(screenNames.sww);
          }
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          className="loginForm"
          onSubmit={(e) => {
            if (document.activeElement?.tagName.toUpperCase() === 'INPUT') {
              document.activeElement.blur();
            }

            handleSubmit(e);
          }}
        >
          <Typography variant="h4">Вход</Typography>

          <FormRow>
            <Input
              label="Логин"
              name="login"
              disabled={isSubmitting}
              autoComplete="username"
              maxLength={100}
            />
          </FormRow>

          <FormRow>
            <PasswordInput
              label="Пароль"
              id="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              disabled={isSubmitting}
              autoComplete="current-password"
              error={Boolean(
                errors.password && touched.password && errors.password
              )}
              helperText={
                errors.password && touched.password && errors.password
              }
              inputProps={{
                maxLength: 50,
              }}
            />
          </FormRow>

          <Box mb="34px">
            <ButtonLoading
              type="submit"
              isLoading={isSubmitting}
              variant="contained"
              color="primary"
            >
              ВОЙТИ
            </ButtonLoading>
          </Box>

          <Link href="mailto:dev@21vek.by">Написать в поддержку</Link>
        </form>
      )}
    </Formik>
  );
};
