import { FC, useState } from 'react';
import { RenderElementProps, useSlate } from 'slate-react';
import { makeStyles, Tooltip } from '@material-ui/core';

import { LINK_MODAL } from '../TextEditor.constants';
import { updateLink } from '../vendors';
import { LinkModal } from '../LinkModal';
import { LinkElement } from '../TextEditor.types';
import styles from './Element.module.scss';

interface LinkProps extends Omit<RenderElementProps, 'element'> {
  element: LinkElement;
}

const useMuiStyles = makeStyles(() => ({
  tooltip: { maxHeight: 102 },
}));

export const LinkComponent: FC<LinkProps> = ({
  attributes,
  children,
  element,
}) => {
  const editor = useSlate();
  const tooltipClasses = useMuiStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleConfirm = (values: { url: string; text: string }) =>
    updateLink({ editor, linkElement: element, ...values });

  const childrenText = element.children.map(({ text }) => text).join('');

  return (
    <>
      <Tooltip title={element.url} classes={tooltipClasses}>
        <a
          {...attributes}
          href={element.url}
          className={styles.linkComponent}
          onClick={openModal}
        >
          {children}
        </a>
      </Tooltip>
      <LinkModal
        title={LINK_MODAL.editTitle}
        url={element.url}
        text={childrenText}
        isModalOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
      />
    </>
  );
};
