import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  crisp: {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    padding: '0 4px 0 12px',
    borderRadius: '3px',
    border: '1px solid var(--black-800)',
    margin: '0 4px 8px 0',
    fontSize: '12px',
    lineHeight: '16px',
    color: 'var(--black-900)',

    '& > span': {
      display: 'flex',

      '& > svg': {
        color: 'var(--grey-400)',
      },
    },
  },
  removeHover: {
    color: 'var(--pink-500)',
    border: '1px solid var(--pink-500)',
    cursor: 'pointer',

    '& > span': {
      '& > svg': {
        color: 'var(--pink-500)',
      },
    },
  },
}));
