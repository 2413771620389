import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { IconButton, InputAdornment } from '@material-ui/core';

import { InputBase } from './InputBase';
import { InputLabel } from './InputLabel';
import { CrossIcon } from '../Icons';

export const Input = forwardRef(
  (
    {
      label,
      tooltip,
      required,
      maxLength,
      hideValidation,
      helperText,
      fixedHelperText,
      withLongLabelTooltip,
      inputRef,
      clearButton,
      contrastingLabel,
      ...props
    },
    ref
  ) => {
    const [field, meta] = useField(props);
    let calcHelperText = (!hideValidation && meta?.error) || helperText;

    if (!calcHelperText && fixedHelperText) calcHelperText = ' ';

    const handleClear = () => {
      props.onChange({ target: { value: '' } });
    };

    return (
      <InputBase
        label={
          label ? (
            <InputLabel
              label={label}
              tooltip={tooltip}
              inputRef={inputRef}
              required={required}
              withLongLabelTooltip={withLongLabelTooltip}
              contrastingLabel={contrastingLabel}
            />
          ) : null
        }
        maxLength={maxLength}
        helperText={calcHelperText}
        error={!hideValidation && Boolean(meta?.error)}
        inputRef={inputRef}
        ref={ref}
        InputProps={{
          endAdornment:
            clearButton && !!props.value.length ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="reset field"
                  edge="end"
                  size="small"
                  onClick={handleClear}
                >
                  <CrossIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
        {...field}
        {...props}
      />
    );
  }
);

Input.propTypes = {
  maxLength: PropTypes.number,
  label: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  hideValidation: PropTypes.bool,
  fixedHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  withLongLabelTooltip: PropTypes.bool,
  inputRef: PropTypes.any,
  clearButton: PropTypes.bool,
  contrastingLabel: PropTypes.bool,
};

Input.defaultProps = {
  tooltip: null,
  hideValidation: false,
  fixedHelperText: false,
  clearButton: false,
  helperText: '',
};
