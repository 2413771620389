import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import TableHead from './TableHead';
import TableRow from './TableRow';
import TableCell from './TableCell';
import cellRenderers from './cellRenderers';
import TableBody from './TableBody';
import { tableActionModel } from '../DraggableTable/DraggableActionsCell';
import { ActionsCell } from './ActionsCell';

const TableList = ({
  actions,
  className,
  data,
  field,
  header,
  onRowClick,
  rowIcon,
  showIcon,
}) => {
  const hasActions = actions && actions.length > 0;

  const rowClickHandler = useCallback(
    (e) => {
      const { index } = e.currentTarget.dataset;

      if (onRowClick && index) {
        onRowClick(data[index]);
      }
    },
    [onRowClick, data]
  );

  return (
    <div className={className}>
      {header && (
        <TableHead>
          <TableRow>
            <TableCell value={header} renderer={cellRenderers.listHead} />
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {data.map((row, i) => (
          <TableRow
            key={row.id}
            data-index={i}
            onClick={onRowClick && rowClickHandler}
          >
            {showIcon?.(row) && rowIcon}

            <TableCell value={row[field]} />

            {hasActions ? <ActionsCell actions={actions} row={row} /> : null}
          </TableRow>
        ))}
      </TableBody>
    </div>
  );
};

TableList.propTypes = {
  actions: PropTypes.arrayOf(tableActionModel).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  showIcon: PropTypes.func,
  rowIcon: PropTypes.func,
  onRowClick: PropTypes.func,
  className: PropTypes.string,
  field: PropTypes.string,
  header: PropTypes.string,
};

TableList.defaultProps = {
  field: 'value',
  header: '',
  className: '',
};

export default TableList;
