import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  row: {
    marginTop: 'unset',
    marginBottom: 'unset',
    '& > .MuiGrid-item': {
      paddingTop: 'unset',
      paddingBottom: 'unset',
      marginBottom: '2.5rem',
    },
  },
}));

function FormRow({ children, spacing = 3, ...rest }) {
  const classes = useStyles();

  return (
    <Grid classes={{ root: classes.row }} container spacing={spacing} {...rest}>
      {children}
    </Grid>
  );
}

FormRow.propTypes = {
  spacing: PropTypes.number,
};

export default FormRow;
