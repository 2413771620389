import {
  FC,
  Children,
  isValidElement,
  cloneElement,
  memo,
  useMemo,
  useCallback,
  ReactElement,
} from 'react';
import { Box, MenuList, Typography } from '@material-ui/core';
import { useControlled } from '@material-ui/core/utils';
import clsx from 'clsx';

import { VirtualizedMenuList } from '../VirtualizedMenuList';
import { SelectListLabel } from './SelectListLabel';
import { useStyles } from './SelectList.styles';

const MAX_ITEMS_PER_VIEW = 7;
const DOUBLE_LINE_ITEM_HEIGHT = 32;

interface SelectListProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string[];
  value?: string[];
  searchInput?: React.ReactNode;
  listClassName?: string;
  isVirtualizedMenuList?: boolean;
  label?: string;
  singleOption?: boolean;
  maxItemsPerView?: number;
  withLongLabelTooltip?: boolean;
  helperText?: string;
  helperTextClassName?: string;
  description?: string;
  contrastingLabel?: boolean;
}

const SelectList: FC<SelectListProps> = ({
  description,
  children,
  className,
  disabled,
  name,
  onChange,
  defaultValue,
  value: valueProp,
  searchInput,
  listClassName,
  isVirtualizedMenuList,
  label,
  singleOption,
  helperText,
  helperTextClassName,
  maxItemsPerView,
  withLongLabelTooltip,
  contrastingLabel,
}) => {
  const [value, setValue] = useControlled({
    controlled: valueProp,
    default: defaultValue,
    name: 'SelectList',
  });

  const classes = useStyles({ contrastingLabel });

  const updateValue = useCallback(
    (itemIndex, itemValue) => {
      if (singleOption) {
        return [itemValue];
      }

      const newValue = value.slice();

      if (itemIndex === -1) {
        newValue.push(itemValue);
      } else {
        newValue.splice(itemIndex, 1);
      }

      return newValue;
    },
    [value, singleOption]
  );

  const handleItemClick = useCallback(
    (child) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (Array.isArray(value)) {
        const itemValue = child.props.value;
        const itemIndex = value.indexOf(itemValue);

        const newValue = updateValue(itemIndex, itemValue);

        if (child.props.onClick) {
          child.props.onClick(event);
        }

        setValue(newValue);

        if (onChange) {
          Object.defineProperty(event, 'target', {
            writable: true,
            value: {
              name,
              value: newValue,
              diff: child.props.value,
              checked: !child.props.checked,
            },
          });

          onChange(event);
        }
      }
    },
    [value, updateValue, setValue, onChange, name]
  );

  const items = useMemo(
    () =>
      Children.toArray(children).map((child) => {
        if (!isValidElement(child)) {
          return null;
        }

        return cloneElement(child as ReactElement, {
          value: undefined,
          className: classes.listItem,
          onMouseDown: handleItemClick(child),
          selected: !!child.props.checked,
          disabled: disabled || child.props.disabled,
          'data-value': child.props.value,
        });
      }),
    [children, classes, disabled, handleItemClick]
  );

  const optionsCount = useMemo(() => items.length, []);

  const listHeight =
    Math.min(optionsCount, maxItemsPerView || MAX_ITEMS_PER_VIEW) *
    DOUBLE_LINE_ITEM_HEIGHT;

  const showSearchInput =
    searchInput && maxItemsPerView
      ? optionsCount > maxItemsPerView
      : optionsCount > MAX_ITEMS_PER_VIEW;

  return (
    <Box className={clsx(classes.wrapper, className)}>
      {label && (
        <SelectListLabel
          label={label}
          description={description}
          showTooltip={withLongLabelTooltip}
          contrastingLabel={contrastingLabel}
        />
      )}
      {showSearchInput && searchInput}
      {items.length !== 0 && (
        <>
          {isVirtualizedMenuList ? (
            <VirtualizedMenuList
              width="auto"
              items={items}
              height={listHeight}
              listClassName={clsx(classes.list, listClassName)}
            />
          ) : (
            <MenuList
              className={clsx(classes.list, listClassName)}
              style={{ maxHeight: listHeight * 3 }}
            >
              {items}
            </MenuList>
          )}
        </>
      )}
      {helperText && (
        <Typography
          className={clsx(classes.helperText, helperTextClassName)}
          style={{ height: listHeight }}
          align="center"
          variant="body2"
          color="textSecondary"
        >
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

SelectList.displayName = 'SelectList';

export default memo(SelectList);
