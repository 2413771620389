import React from 'react';

export const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="shape"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.70726 5.29289L6.00015 4.58579L4.58594 6L5.29305 6.70711L10.5859 12L5.29303 17.2929L4.58592 18L6.00014 19.4142L6.70724 18.7071L12.0001 13.4142L17.293 18.7071L18.0001 19.4142L19.4144 18L18.7073 17.2929L13.4144 12L18.7072 6.70709L19.4144 5.99998L18.0001 4.58577L17.293 5.29288L12.0001 10.5858L6.70726 5.29289Z"
      fill="currentColor"
    />
  </svg>
);
