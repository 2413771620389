import React from 'react';

export const ArrowDownIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.5 9.5L11.5 14.5L16.5 9.5L6.5 9.5Z" fill="currentColor" />
  </svg>
);
