import React from 'react';
import MaterialTableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';

import { useStyles } from './tableStyles';

const TableRow = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <MaterialTableRow
      className={className}
      classes={{
        root: classes.tableRow,
        head: classes.tableRowHead,
        hover: classes.tableRowHover,
      }}
      hover={!!props.onClick}
      component="div"
      {...props}
    />
  );
};

TableRow.propTypes = {
  onClick: PropTypes.func,
  onPointerDown: PropTypes.func,
  className: PropTypes.string,
};

export default TableRow;
