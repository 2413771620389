import { FC } from 'react';
import { Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Loading } from '../Loading';

export interface LoadingBackdropProps {
  open: boolean;
  containerClassName?: string;
  circularClassName?: string;
}

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 500,
  },
  circular: {
    color: 'white',
  },
}));

export const LoadingBackdrop: FC<LoadingBackdropProps> = ({
  open,
  containerClassName,
  circularClassName,
}) => {
  const classes = useStyles();

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <Loading
        className={containerClassName}
        circularClassName={clsx(classes.circular, circularClassName)}
      />
    </Backdrop>
  );
};
