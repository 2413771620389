import React  from 'react';
import { Header } from '../../components/Header';
import { LoginForm } from './LoginForm';

import './LoginScreen.css';


export const LoginScreen = () => (
  <div className="page page-login">
    <div className="pageContent">
      <Header />
      <section className="formWrapper">
        <LoginForm />
      </section>
    </div>
  </div>
);
