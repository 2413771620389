import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  blockTitle: {
    color: 'var(--black-900)',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },
}));

const FormGroupTitle = ({ className, children }) => {
  const classes = useStyles();

  return (
    <div role="textbox" className={clsx(classes.blockTitle, className)}>
      {children}
    </div>
  );
};

FormGroupTitle.propTypes = {
  className: PropTypes.string,
};

export default FormGroupTitle;
