import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FinanceIcon, NavigationIcon, ExploreIcon, CatalogIcon, BulbIcon } from '@21vek/admin-front-components';
import { ForbiddenScreen } from '../screens/ForbiddenScreen';
import { LoginScreen } from '../screens/LoginScreen';
import { getAdminPartRoute, isItAdminPartMainRoute } from '../lib';
import { MainScreen } from '../screens/MainScreen';
import {
  getFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
  UNAUTHORIZED_USER_URL
} from "../lib/sessionStorage";

export const ROUTES = {
  login: '/login',
  partlyPay: {
    credits: '/partly-pay/credits',
    archive: '/partly-pay/credits/archive',
    terms: '/partly-pay/terms',
    banks: '/partly-pay/banks',
  },
  legalEntity: {
    unp: '/legal-entity/unp',
    tnn: '/legal-entity/tnn',
  },
  navigation: {
    promo: '/navigation/promo',
  },
  featureToggles: {
    flags: '/feature-toggles/flags',
  },
  promocodes: {
    list: '/promocodes/list',
    add: '/promocodes/add',
    edit: '/promocodes/edit',
    duplicate: '/promocodes/duplicate'
  },
  catalog: {
    brands: '/catalog/brands',
    selections: '/catalog/selections'
  },
  adgames: {
    main: '/adgames',
    list: '/adgames/list',
  },
  segments: {
    list: '/segments/list',
    add: '/segments/add',
    edit: '/segments/edit',
    duplicate: '/segments/duplicate'
  },
  products: {
    editor: '/products',
    list: '/products/list',
  }
};

const LIST_ROLES = Object.freeze({
  PartlyPay: { partRoute: '/partly-pay', role: 'PartlyPay' },
  FeatureToggles: { partRoute: '/feature-toggles', role: 'FeatureToggles' },
  Navigation: { partRoute: '/navigation', role: 'Navigation' },
  Promocodes: { partRoute: '/promocodes', role: 'Promocodes' },
  AdGames: { partRoute: '/adgames', role: 'adgames' },
  Catalog: { partRoute: '/catalog', role: 'Catalog' },
  Segments: { partRoute: '/segments', role: 'ProductSegments'},
  Products: { partRoute: '/products', role: 'Products'}
});

const ROUTES_PATHS = Object.values(LIST_ROLES).map(
  (routeConfig) => routeConfig.partRoute,
);

const ADMIN_MENU = [
  {
    icon: <FinanceIcon />,
    title: 'Финансы',
    routes: [
      {
        title: 'Рассрочки, кредиты',
        path: ROUTES.partlyPay.credits,
        roleInfo: LIST_ROLES.PartlyPay,
      },
      {
        title: 'Условия',
        path: ROUTES.partlyPay.terms,
        roleInfo: LIST_ROLES.PartlyPay,
      },
      {
        title: 'Банки',
        path: ROUTES.partlyPay.banks,
        roleInfo: LIST_ROLES.PartlyPay,
      },
    ],
  },
  {
    icon: <ExploreIcon />,
    title: 'Управление',
    routes: [
      {
        title: 'Флаги',
        path: ROUTES.featureToggles.flags,
        roleInfo: LIST_ROLES.FeatureToggles,
      },
      {
        title: 'Промокоды',
        path: ROUTES.promocodes.list,
        roleInfo: LIST_ROLES.Promocodes,
      },
      {
        title: 'Сегменты товаров',
        path: ROUTES.segments.list,
        roleInfo: LIST_ROLES.Segments,
      },
      {
        title: 'Товары',
        path: ROUTES.products.list,
        roleInfo: LIST_ROLES.Products,
      },
    ],
  },
  {
    icon: <NavigationIcon />,
    title: 'Навигация',
    routes: [
      {
        title: 'Промонавигация',
        path: ROUTES.navigation.promo,
        roleInfo: LIST_ROLES.Navigation,
      },
    ],
  },
  {
    icon: <BulbIcon />,
    title: 'Маркетинг',
    routes: [
      {
        title: 'Рекламные игры',
        path: ROUTES.adgames.list,
        roleInfo: LIST_ROLES.AdGames,
      }
    ]
  }, {
    icon: <CatalogIcon />,
    title: 'Каталог',
    partRoute: '/catalog',
    routes: [
      {
        title: 'Подборки',
        path: ROUTES.catalog.selections,
        roleInfo: LIST_ROLES.Catalog,
      },
      {
        title: 'Производители',
        path: ROUTES.catalog.brands,
        roleInfo: LIST_ROLES.Catalog,
      },
    ]
  }
];

export function getAvailableMenuByRoles(roles) {
  if (!roles || roles.length === 0) {
    return [];
  }

  return ADMIN_MENU.reduce((acc, item) => {
    const availableRoutes = item.routes.reduce((acc, route) => {
      if (roles.includes(route.roleInfo.role)) {
        acc.push(route);
      }

      return acc;
    }, []);

    if (availableRoutes?.length > 0) {
      item.routes = availableRoutes;
      acc.push(item);
    }

    return acc;
  }, []);
}

export const AuthenticatedRoutes = ({ disabledSidebar, userInfo, children }) => {
  const availableRoutes = getAvailableMenuByRoles(userInfo.roles);
  const currentPartRouteStr = getAdminPartRoute();

  const currentPartRoute = availableRoutes.find(
    ({ routes }) => routes.find(({ roleInfo }) => roleInfo.partRoute === currentPartRouteStr),
  );
  const hasAccess = !!currentPartRoute;

  return (
    <Switch>
      <Route exact path={ROUTES.login}>
        <Redirect to="/" />
      </Route>
      <Route
        exact
        path="/"
        render={() => {
          const redirectTo = getFromSessionStorage(UNAUTHORIZED_USER_URL);

          if (redirectTo) {
            removeFromSessionStorage(UNAUTHORIZED_USER_URL);
          }

          if (availableRoutes.length > 0)  {
            return window.location.assign(redirectTo || availableRoutes[0].routes[0].path);
          }

          return <ForbiddenScreen />;
        }}
      />
      <Route
        path={ROUTES_PATHS}
        render={() => {
          if (!hasAccess) {
            return <Redirect to="/" />;
          }

          return (
            <MainScreen adminPartsLinks={availableRoutes} disabledSidebar={disabledSidebar}>
              {children}
            </MainScreen>
          );
        }}
      />
    </Switch>
  );
};

export const UnauthenticatedRoutes = () => {
  if (location.pathname !== ROUTES.login) {
    saveToSessionStorage(UNAUTHORIZED_USER_URL, location.href);
  }

  return (
    <>
      <Route exact path={ROUTES.login}>
        <LoginScreen/>
      </Route>
      <Route path="/">
        <Redirect to={ROUTES.login}/>
      </Route>
    </>
  );
};

AuthenticatedRoutes.propTypes = {
  userInfo: PropTypes.shape({
    token: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};
