import { createContext, useState, useCallback } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Fade, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DoneIcon, DeleteIcon, ErrorIcon } from '@21vek/admin-front-components';

export const SnackbarContext = createContext({ open: null, close: null });
SnackbarContext.displayName = 'SnackbarContext';

export const SNACKBAR_TYPES = {
  done: 'DONE',
  delete: 'DELETE',
  error: 'ERROR',
};

const DEFAULT_DURATION = 2000;

function getIconByType(type) {
  switch (type) {
    case SNACKBAR_TYPES.done:
      return <DoneIcon />;
    case SNACKBAR_TYPES.delete:
      return <DeleteIcon />;
    case SNACKBAR_TYPES.error:
      return <ErrorIcon />;
    default:
      return <DoneIcon />;
  }
}

export const useStyles = makeStyles(() => ({
  root: {
    whiteSpace: 'pre',
  },
}));

export function SnackbarProvider({ children }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: null,
    type: SNACKBAR_TYPES.done,
    duration: DEFAULT_DURATION,
  });

  const classes = useStyles();

  const closeHandler = useCallback((_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar((state) => ({ ...state, open: false }));
  }, []);

  const openHandler = useCallback((msg, type, duration) => {
    setSnackbar({ open: true, message: msg, type, duration });
  }, []);

  return (
    <SnackbarContext.Provider
      value={{ open: openHandler, close: closeHandler }}
    >
      <Snackbar
        classes={classes}
        disableWindowBlurListener
        autoHideDuration={snackbar.duration ?? DEFAULT_DURATION}
        open={snackbar.open}
        TransitionComponent={Fade}
        message={
          <>
            {getIconByType(snackbar.type)}
            <Typography variant="body1" component="span">
              {snackbar.message}
            </Typography>
          </>
        }
        onClose={closeHandler}
      />
      {children}
    </SnackbarContext.Provider>
  );
}
