import React from 'react';

export const PasswordHideIcon = () => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12.0036 8.15789C14.0118 8.15789 15.6417 9.92632 15.6417 12.1053C15.6417 12.6184 15.5471 13.1 15.3797 13.55L17.5043 15.8553C18.603 14.8605 19.4688 13.5737 20 12.1053C18.7412 8.63947 15.6344 6.18421 11.9964 6.18421C10.9777 6.18421 10.0027 6.38158 9.1005 6.73684L10.6721 8.44211C11.0869 8.26053 11.5307 8.15789 12.0036 8.15789ZM4.7276 6.00263L6.38654 7.80263L6.72124 8.16579C5.51341 9.18421 4.56753 10.5421 4 12.1053C5.25875 15.5711 8.36562 18.0263 12.0036 18.0263C13.1314 18.0263 14.2083 17.7895 15.1905 17.3632L15.4961 17.6947L17.628 20L18.5521 18.9974L5.65166 5L4.7276 6.00263ZM8.75125 10.3684L9.87904 11.5921C9.84266 11.7579 9.82083 11.9316 9.82083 12.1053C9.82083 13.4158 10.7958 14.4737 12.0036 14.4737C12.1637 14.4737 12.3238 14.45 12.4766 14.4105L13.6044 15.6342C13.1169 15.8947 12.5784 16.0526 12.0036 16.0526C9.99545 16.0526 8.36562 14.2842 8.36562 12.1053C8.36562 11.4816 8.51114 10.8974 8.75125 10.3684ZM11.8872 9.75263L14.1792 12.2395L14.1937 12.1132C14.1937 10.8026 13.2187 9.74474 12.0109 9.74474L11.8872 9.75263Z"
      fill="currentColor"
    />
  </svg>
);
