import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CircularProgress } from '@material-ui/core';
import { useStyles } from './loadingScreenStyles';


export const LoadingScreen = ({ text }) => {
  const classes = useStyles();

  return (
    <div className="page loaderScreen">
      <CircularProgress
        classes={{
          root: classes.rootProgress,
        }}
        size={24}
      />
      <Typography variant="body1">{text}</Typography>
    </div>
  );
};

LoadingScreen.propTypes = {
  text: PropTypes.string,
};

LoadingScreen.defaultProps = {
  text: 'Загрузка…',
};
