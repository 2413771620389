import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  disabled: {},
  link: {
    color: theme.palette.primary.light,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&$disabled': {
      color: theme.palette.grey[500],
      pointerEvents: 'none',
    },
  },
}));
