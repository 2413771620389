import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import PropTypes from 'prop-types';

function DatePickerProvider({ children }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      {children}
    </MuiPickersUtilsProvider>
  );
}

DatePickerProvider.propTypes = {
  children: PropTypes.element,
};

export default DatePickerProvider;
