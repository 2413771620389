import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const Loading = ({ className, circularClassName }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, className)}>
      <CircularProgress size={24} className={circularClassName} />
    </Box>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
  circularClassName: PropTypes.string,
};
