import React from 'react';
import PropTypes from 'prop-types';
import { Button as MaterialButton, CircularProgress } from '@material-ui/core';

import { useStyles } from './buttonLoadingStyles';

/**
 *   Кнопка с лоадером, реализованна на базе
 *   <a href="https://material-ui.com/ru/components/buttons/#button">Button из material</a>,
 *   можно использовать все те же пропсы что и у Button
 */
export const ButtonLoading = ({ isLoading, disabled, children, ...props }) => {
  const { progressWrapper, disabled: disabledClassName } = useStyles({
    isLoading,
    disabled,
  });

  return (
    <MaterialButton
      classes={{
        disabled: disabledClassName,
      }}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading && !disabled ? (
        <div className={progressWrapper}>
          <CircularProgress color="inherit" thickness={3.6} size="1.334em" />
        </div>
      ) : null}
      {children}
    </MaterialButton>
  );
};

ButtonLoading.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

ButtonLoading.defaultProps = {
  isLoading: false,
  disabled: false,
};
