import React from 'react';

export const CatalogIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="#1A1A1A"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6ZM7.55554 16.4444H13.7778V14.6667H7.55554V16.4444ZM7.55554 12.8889H16.4444V11.1111H7.55554V12.8889ZM7.55554 9.33332H16.4444V7.55554H7.55554V9.33332Z"
    />
  </svg>
);
