import { FC } from 'react';

export const BookmarkIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="56" viewBox="0 0 32 56" fill="none">
    <g filter="url(#filter0_dd_26611_48563)">
      <path d="M0 0.667178C0 8.91859 5.99998 8.9186 6 8.9186C6 8.9186 6 8.9186 6 8.9186H17C20.866 8.9186 24 12.0526 24 15.9186V40.0814C24 43.9474 20.866 47.0814 17 47.0814H6.00008C6.00003 47.0814 6.00006 47.0814 6 47.0814C5.99816 47.0814 0 47.0829 0 55.3329C0 63.5841 0 -7.58425 0 0.667178Z" fill="white"/>
    </g>
    <defs>
      <filter id="filter0_dd_26611_48563" x="-8" y="-6" width="40" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0295189 0 0 0 0 0.137798 0 0 0 0 0.187773 0 0 0 0.16 0"/>
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_26611_48563"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="4"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0295189 0 0 0 0 0.137798 0 0 0 0 0.187773 0 0 0 0.04 0"/>
        <feBlend mode="multiply" in2="effect1_dropShadow_26611_48563" result="effect2_dropShadow_26611_48563"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_26611_48563" result="shape"/>
      </filter>
    </defs>
  </svg>
);
