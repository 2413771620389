import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '8px',
  },
  rootTypographyBody: {
    maxWidth: '480px',
    margin: '0 auto',
  }
}));
