import {
  Children,
  cloneElement,
  isValidElement,
  useState,
  useEffect,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link, Menu, Typography, Fade } from '@material-ui/core';

import { ArrowDownIcon, ArrowUpIcon } from '../Icons';
import { ButtonWithIcon } from '../ButtonWithIcon';
import { useStyles } from './dropdownStyles';

/**
 *   Компонент для реализации выпадающих списков.<br>
 *   За отображение элемента списка отвечает компонент SelectMenuItem
 */
function Dropdown({
  children,
  anchorOrigin,
  transformOrigin,
  disabled: disabledProp,
  className,
  closedIcon,
  openedIcon,
  label,
  link,
  colored,
  MenuListProps = {},
  variant,
  size,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const disabled = disabledProp || Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  useEffect(() => {
    if (!disabled) {
      window.addEventListener('resize', () => setAnchorEl(null));

      return () =>
        window.removeEventListener('resize', () => setAnchorEl(null));
    }
  }, [setAnchorEl, disabled]);

  const button = link ? (
    /* eslint-disable jsx-a11y/anchor-is-valid */
    <Link
      className={clsx(classes.link, {
        [classes.disabled]: disabled,
      })}
      onClick={handleClick}
      underline="none"
    >
      {anchorEl ? openedIcon : closedIcon}
      <Typography variant="button">{label}</Typography>
    </Link>
  ) : (
    <ButtonWithIcon
      color="primary"
      icon={anchorEl ? openedIcon : closedIcon}
      onClick={handleClick}
      variant={variant}
      size={size}
      disabled={disabled}
    >
      {label}
    </ButtonWithIcon>
  );

  const childrenArray = Children.toArray(children);

  const handleItemClick = (child) => (event) => {
    setAnchorEl(null);

    if (child.props.onClick) {
      Object.defineProperty(event, 'target', {
        value: { value: child.props.value },
      });
      child.props.onClick(event);
    }
  };

  const items = childrenArray.map((child) => {
    if (!isValidElement(child)) {
      return null;
    }

    return cloneElement(child, {
      onClick: handleItemClick(child),
      colored,
    });
  });

  return (
    <div className={className}>
      {button}
      <Menu
        key={`${disabled}`}
        autoFocus={false}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        getContentAnchorEl={null}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        TransitionComponent={Fade}
        MenuListProps={MenuListProps}
      >
        {items}
      </Menu>
    </div>
  );
}

const OriginPropTypes = PropTypes.shape({
  horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
});

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   *   Точка привязки, к которой будет прикрепляться anchorEl.
   *   Варианты: по вертикали: ['left', 'center', 'right']; по горизонтали: ['top', 'center', 'bottom'].
   */
  anchorOrigin: OriginPropTypes,
  /**
   *   Точка во всплывающем окне, которая будет прикреплена к исходной точке привязки.
   *   Варианты: по вертикали: ['left', 'center', 'right']; по горизонтали: ['top', 'center', 'bottom'].
   */
  transformOrigin: OriginPropTypes,
  /**
   *   Состояние блокировки меню
   */
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  /**
   *   Отображение кнопки как ссылки
   */
  link: PropTypes.bool,
  /**
   *   Иконка в закрытом состоянии
   */
  closedIcon: PropTypes.node,
  /**
   *   Иконка в развернутом состоянии
   */
  openedIcon: PropTypes.node,
  /**
   *   Отображение цветных кружков у items
   */
  colored: PropTypes.bool,
  /**
   *   Свойства внутреннего компонента MenuList
   */
  MenuListProps: PropTypes.object,
  /**
   *   Тип кнопки
   */
  variant: PropTypes.oneOf(['contained', 'outlined']),
  /**
   *   Размер кнопки
   */
  size: PropTypes.oneOf(['medium', 'small']),
};

Dropdown.defaultProps = {
  anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
  transformOrigin: { horizontal: 'left', vertical: 'top' },
  closedIcon: <ArrowDownIcon />,
  openedIcon: <ArrowUpIcon />,
  link: false,
  colored: false,
  variant: 'contained',
  size: 'medium',
};

export default Dropdown;
