import React from 'react';
import ReactDOM from 'react-dom';
import CommonApp from '@21vek/admin-front-common';


ReactDOM.render(
  <React.StrictMode>
    <CommonApp />
  </React.StrictMode>,
  document.getElementById('root')
);

